(function() {
  var Experience, Item, Pokemon, alias, data, dataEncryption, languages, pkmnBaseData, textEncoding, _;

  _ = require('lodash');

  textEncoding = require('./text-encoding');

  languages = require('./constants').languages;

  dataEncryption = (require('./encryption')).pokemonData;

  Item = require('./item');

  Experience = require('./experience');

  alias = require('./util').alias;

  data = require('../data/pokemon.json');

  pkmnBaseData = require('../data/base-data.json');

  module.exports = Pokemon = (function() {
    Pokemon.marking = {
      circle: 0x1,
      box: 0x2,
      triangle: 0x4,
      heart: 0x8
    };

    Pokemon.prototype.statNames = ['hp', 'atk', 'def', 'spd', 'spAtk', 'spDef'];

    Pokemon.fromBuffer = function(buffer) {
      if (_.all(buffer, function(b) {
        return b === 0;
      })) {
        return null;
      }
      return new Pokemon(buffer);
    };

    function Pokemon(buffer) {
      this.read(buffer);
      Object.defineProperties(this, {
        originalPublicId: {
          enumerable: false,
          get: (function(_this) {
            return function() {
              return _this.originalTrainerId & 0xFFFF;
            };
          })(this)
        },
        originalPrivateId: {
          enumerable: false,
          get: (function(_this) {
            return function() {
              return (_this.originalTrainerId >> 16) & 0xFFFF;
            };
          })(this)
        },
        level: {
          enumerable: true,
          get: (function(_this) {
            return function() {
              var growthType;
              growthType = pkmnBaseData[_this.speciesIndex].experienceCurve;
              return Experience.levelFromExperience(growthType, _this.experience);
            };
          })(this),
          set: (function(_this) {
            return function(lvl) {
              var growthType;
              growthType = pkmnBaseData[_this.speciesIndex].experienceCurve;
              return _this.experience = Experience.calculate(growthType, lvl);
            };
          })(this)
        },
        natureIndex: {
          enumerable: false,
          get: (function(_this) {
            return function() {
              return _this.personalityValue % 25;
            };
          })(this)
        },
        nature: {
          enumerable: true,
          get: (function(_this) {
            return function() {
              return data.natures[_this.natureIndex];
            };
          })(this)
        },
        gender: {
          enumerable: true,
          get: (function(_this) {
            return function() {
              var baseGender, gender;
              baseGender = pkmnBaseData[_this.speciesIndex].gender;
              switch (baseGender) {
                case 0xFF:
                  return null;
                case 0xFE:
                  return 'female';
                case 0x00:
                  return 'male';
                default:
                  gender = _this.personalityValue & 0xFF;
                  if (gender >= baseGender) {
                    return 'male';
                  } else {
                    return 'female';
                  }
              }
            };
          })(this)
        },
        shiny: {
          enumerable: true,
          get: (function(_this) {
            return function() {
              var shininess;
              shininess = _this.originalPublicId ^ _this.originalPrivateId ^ (_this.personalityValue & 0xFFFF) ^ (_this.personalityValue >> 16) & 0xFFFF;
              return shininess < 8;
            };
          })(this)
        }
      });
      alias(this, 'level', 'lvl', 'lv');
      alias(this, 'experience', 'exp', 'xp');
    }

    Pokemon.prototype.read = function(buffer) {
      var dataChecksum, pkmnData;
      this.personalityValue = buffer.readUInt32LE(0x00);
      this.originalTrainerId = buffer.readUInt32LE(0x04);
      this.name = textEncoding.decode(buffer.slice(0x08, 0x08 + 10));
      this.language = languages[buffer.readUInt16LE(0x12)];
      this.originalTrainerName = textEncoding.decode(buffer.slice(0x14, 0x14 + 7));
      this.markings = buffer.readUInt32LE(0x1B);
      dataChecksum = buffer.readUInt16LE(0x1C);
      pkmnData = dataEncryption.decrypt(this.originalTrainerId ^ this.personalityValue, buffer.slice(0x20, 0x20 + 48));
      return this.readData(pkmnData);
    };

    Pokemon.prototype.calculateDataOrder = function() {
      var baseOrder, i, order, orderNum, _i, _len, _ref, _ref1;
      orderNum = this.personalityValue % 24;
      baseOrder = ['growth', 'attacks', 'evsCondition', 'misc'];
      order = [];
      _ref = [Math.floor(orderNum / 6), Math.floor((orderNum % 6) / 2), orderNum % 2];
      for (_i = 0, _len = _ref.length; _i < _len; _i++) {
        i = _ref[_i];
        order.push(baseOrder[i]);
        [].splice.apply(baseOrder, [i, i - i + 1].concat(_ref1 = [])), _ref1;
      }
      order.push(baseOrder[0]);
      return order;
    };

    Pokemon.prototype.readData = function(buffer) {
      var baseData, i, substructSlice, substructType, _i, _len, _ref, _ref1;
      _ref = this.calculateDataOrder();
      for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
        substructType = _ref[i];
        substructSlice = buffer.slice(i * 12, (i + 1) * 12);
        switch (substructType) {
          case 'growth':
            this.readGrowth(substructSlice);
            break;
          case 'attacks':
            this.readAttacks(substructSlice);
            break;
          case 'evsCondition':
            this.readEvsCondition(substructSlice);
            break;
          case 'misc':
            this.readMisc(substructSlice);
        }
      }
      baseData = pkmnBaseData[this.speciesIndex];
      this.abilityIndex = this.ability;
      return this.ability = (_ref1 = baseData.ability) != null ? _ref1 : baseData.abilities[this.ability];
    };

    Pokemon.prototype.readGrowth = function(buffer) {
      var heldItem, ppBonuses;
      this.speciesIndex = buffer.readUInt16LE(0x0);
      this.species = data.pokemon[this.speciesIndex];
      heldItem = buffer.readUInt16LE(0x2);
      if (!Item.isNothing(heldItem)) {
        this.heldItem = Item.readType(heldItem);
      }
      this.experience = buffer.readUInt32LE(0x4);
      ppBonuses = buffer.readUInt8(0x8);
      this.ppBonuses = [ppBonuses & 0x3, ppBonuses & 0xc, ppBonuses & 0x30, ppBonuses & 0xc0];
      this.friendship = buffer.readUInt8(0x9);
      return this._unknownGrowthBytes = buffer.readUInt16LE(0xA);
    };

    Pokemon.prototype.readAttacks = function(buffer) {
      var i, move, readMove;
      readMove = function(index) {
        var move;
        move = buffer.readUInt16LE(0x0 + index * 2);
        if (move === 0) {
          return null;
        }
        return {
          name: data.moves[move],
          pp: buffer.readUInt8(0x8 + index)
        };
      };
      return this.moves = (function() {
        var _i, _results;
        _results = [];
        for (i = _i = 0; _i < 4; i = ++_i) {
          move = readMove(i);
          if (move == null) {
            break;
          }
          _results.push(move);
        }
        return _results;
      })();
    };

    Pokemon.prototype.readEvsCondition = function(buffer) {
      var cond, conditions, i, stat, _i, _j, _len, _len1, _ref;
      conditions = ['coolness', 'beauty', 'cuteness', 'smartness', 'toughness'];
      this.evs = {};
      _ref = this.statNames;
      for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
        stat = _ref[i];
        this.evs[stat] = buffer.readUInt8(0x0 + i);
      }
      this.condition = {};
      for (i = _j = 0, _len1 = conditions.length; _j < _len1; i = ++_j) {
        cond = conditions[i];
        this.condition[cond] = buffer.readUInt8(0x6 + i);
      }
      return this.feel = buffer.readUInt8(0xB);
    };

    Pokemon.prototype.readMisc = function(buffer) {
      var i, ivsEggAbilityData, stat, _i, _len, _ref;
      this.pokerusStatus = buffer.readUInt8(0x0);
      this.metLocation = buffer.readUInt8(0x1);
      this.originInfo = buffer.readUInt16LE(0x2);
      ivsEggAbilityData = buffer.readUInt32LE(0x4);
      this.ivs = {};
      _ref = this.statNames;
      for (i = _i = 0, _len = _ref.length; _i < _len; i = ++_i) {
        stat = _ref[i];
        this.ivs[stat] = (ivsEggAbilityData >> (i * 5)) & 0x1f;
      }
      this.isEgg = ((ivsEggAbilityData >> 30) & 0x1) === 1;
      return this.ability = (ivsEggAbilityData >> 31) & 0x1;
    };

    return Pokemon;

  })();

}).call(this);
