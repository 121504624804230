(function() {
  var GameSave, Savefile;

  GameSave = require('./game-save');

  module.exports = Savefile = (function() {
    function Savefile(buffer) {
      this.read(buffer);
    }

    Savefile.prototype.read = function(buffer) {
      var saveA, saveB, _ref, _ref1;
      saveA = new GameSave(buffer.slice(0, 57344));
      saveB = new GameSave(buffer.slice(57344, 2 * 57344));
      if (saveA.saveIndex > saveB.saveIndex) {
        return _ref = [saveA, saveB], this.current = _ref[0], this.previous = _ref[1], _ref;
      } else {
        return _ref1 = [saveB, saveA], this.current = _ref1[0], this.previous = _ref1[1], _ref1;
      }
    };

    return Savefile;

  })();

}).call(this);
