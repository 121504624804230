(function() {
  var Item, baseDataSize, colors, data, eggGroups, experienceCurves, statNames, types;

  Item = require('./item');

  data = require('../data/pokemon.json');

  statNames = ['hp', 'atk', 'def', 'spd', 'spAtk', 'spDef'];

  exports.baseDataSize = baseDataSize = 28;

  exports.types = types = ['normal', 'fighting', 'flying', 'poison', 'ground', 'rock', 'bug', 'ghost', 'steel', '???', 'fire', 'water', 'grass', 'electric', 'psychic', 'ice', 'dragon', 'dark'];

  exports.experienceCurves = experienceCurves = ['medium-fast', 'erratic', 'fluctuating', 'medium-slow', 'fast', 'slow'];

  exports.colors = colors = ['red', 'blue', 'yellow', 'green', 'black', 'brown', 'purple', 'gray', 'white', 'pink'];

  exports.eggGroups = eggGroups = [null, 'monster', 'water-1', 'bug', 'flying', 'field', 'fairy', 'grass', 'human-like', 'water-3', 'mineral', 'amorphous', 'water-2', 'ditto', 'dragon', 'undiscovered'];

  exports.readEntries = function(buffer) {
    var i, length, structBuf, _i, _results;
    length = Math.floor(buffer.length / baseDataSize);
    _results = [];
    for (i = _i = 0; 0 <= length ? _i < length : _i > length; i = 0 <= length ? ++_i : --_i) {
      structBuf = buffer.slice(i * baseDataSize, (i + 1) * baseDataSize);
      _results.push(exports.read(structBuf));
    }
    return _results;
  };

  exports.read = function(buffer) {
    var baseStats, colorFlip, commonItem, d, effortYield, effortYieldData, i, item, items, rareItem, stat, type1, type2, _i, _j, _len, _len1, _ref;
    baseStats = {};
    for (i = _i = 0, _len = statNames.length; _i < _len; i = ++_i) {
      stat = statNames[i];
      baseStats[stat] = buffer.readUInt8(0x00 + i);
    }
    type1 = types[buffer.readUInt8(0x06)];
    type2 = types[buffer.readUInt8(0x07)];
    effortYield = {};
    effortYieldData = buffer.readUInt16LE(0x0A);
    for (i = _j = 0, _len1 = statNames.length; _j < _len1; i = ++_j) {
      stat = statNames[i];
      effortYield[stat] = (effortYieldData >> (i * 2)) & 0x3;
    }
    items = [buffer.readUInt16LE(0x0C), buffer.readUInt16LE(0x0E)];
    colorFlip = buffer.readUInt8(0x19);
    d = {
      baseStats: baseStats,
      types: type1 === type2 ? [type1, null] : [type1, type2],
      catchRate: buffer.readUInt8(0x08),
      baseExperienceYield: buffer.readUInt8(0x09),
      effortYield: effortYield,
      gender: buffer.readUInt8(0x10),
      eggCycles: buffer.readUInt8(0x11),
      baseFriendship: buffer.readUInt8(0x12),
      experienceCurve: experienceCurves[buffer.readUInt8(0x13)],
      eggGroups: [eggGroups[buffer.readUInt8(0x14)], eggGroups[buffer.readUInt8(0x15)]],
      abilities: [data.abilities[buffer.readUInt8(0x16)], data.abilities[buffer.readUInt8(0x17)]],
      safariFleeRate: buffer.readUInt8(0x18),
      color: colors[colorFlip & 0x7f],
      flipped: (colorFlip >> 7) === 1
    };
    if (type1 === type2) {
      d.type = type1;
    }
    if (d.abilities[1] == null) {
      d.ability = d.abilities[0];
    }
    if (d.eggGroups[0] === d.eggGroups[1]) {
      d.eggGroup = d.eggGroups[0];
    }
    _ref = d.items = (function() {
      var _k, _len2, _results;
      _results = [];
      for (_k = 0, _len2 = items.length; _k < _len2; _k++) {
        item = items[_k];
        if (Item.isNothing(item)) {
          _results.push(null);
        } else {
          _results.push(Item.readType(item));
        }
      }
      return _results;
    })(), commonItem = _ref[0], rareItem = _ref[1];
    if ((commonItem != null) && rareItem === commonItem) {
      d.item = commonItem;
    } else if (commonItem != null) {
      d.commonItem = commonItem;
    } else if (rareItem != null) {
      d.rareItem = rareItem;
    }
    return d;
  };

}).call(this);
