(function() {
  var _;

  _ = require('lodash');

  exports.toEncoded = function(char) {
    switch (false) {
      case !(('A' <= char && char <= 'Z')):
        return 0xBB + (char.charCodeAt(0) - 65);
      case !(('a' <= char && char <= 'z')):
        return 0xD5 + (char.charCodeAt(0) - 97);
      case !(('0' <= char && char <= '9')):
        return 0xA1 + (char.charCodeAt(0) - 48);
      default:
        return 0x00;
    }
  };

  exports.encode = function(str, len) {
    var buf, c, i, _i, _len;
    if (len == null) {
      len = str.length;
    }
    buf = new Buffer(len);
    for (i = _i = 0, _len = str.length; _i < _len; i = ++_i) {
      c = str[i];
      buf.writeUInt8(exports.toEncoded(c), i);
    }
    buf.fill(0xFF, str.length, len);
    return buf;
  };

  exports.toDecoded = function(byte) {
    switch (false) {
      case !((0xBB <= byte && byte <= 0xD4)):
        return String.fromCharCode(65 + (byte - 0xBB));
      case !((0xD5 <= byte && byte <= 0xEE)):
        return String.fromCharCode(97 + (byte - 0xD5));
      case !((0xA1 <= byte && byte <= 0xAA)):
        return String.fromCharCode(48 + (byte - 0xA1));
      default:
        return ' ';
    }
  };

  exports.decode = function(buf, len) {
    var b, chars;
    if (len == null) {
      len = buf.length;
    }
    chars = (function() {
      var _i, _len, _results;
      _results = [];
      for (_i = 0, _len = buf.length; _i < _len; _i++) {
        b = buf[_i];
        if (b === 0xFF) {
          break;
        }
        _results.push(exports.toDecoded(b));
      }
      return _results;
    })();
    return chars.join('');
  };

}).call(this);
