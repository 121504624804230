(function() {
  var data, itemEntryLength, _;

  _ = require('lodash');

  data = require('../data/pokemon.json');

  exports.itemEntryLength = itemEntryLength = 4;

  exports.nothing = 0x00000000;

  exports.isNothing = function(item) {
    switch (false) {
      case !_.isObject(item):
        return item.name === 'Nothing' || item.type === 0 || item.amount === 0;
      case !_.isString(item):
        return item === 'Nothing';
      case !_.isNumber(item):
        return item === exports.nothing;
      default:
        return false;
    }
  };

  exports.readType = function(buffer, offset) {
    if (Buffer.isBuffer(buffer)) {
      return exports.readType(buffer.readUInt16LE(offset));
    } else {
      return data.items[buffer];
    }
  };

  exports.readItem = function(buffer, offset, securityKey) {
    if (securityKey == null) {
      securityKey = 0;
    }
    return {
      name: exports.readType(buffer, offset + 0x00),
      amount: (buffer.readUInt16LE(offset + 0x02)) ^ (securityKey & 0xFFFF)
    };
  };

  exports.readList = function(buffer, offset, numEntries, securityKey) {
    var i, item, _i, _results;
    if (securityKey == null) {
      securityKey = 0;
    }
    if (numEntries == null) {
      numEntries = Math.floor(buffer.length / itemEntryLength);
    }
    _results = [];
    for (i = _i = 0; 0 <= numEntries ? _i < numEntries : _i > numEntries; i = 0 <= numEntries ? ++_i : --_i) {
      item = exports.readItem(buffer, offset + i * itemEntryLength, securityKey);
      if (exports.isNothing(item)) {
        break;
      }
      _results.push(item);
    }
    return _results;
  };

}).call(this);
