{
  "1": "Master Ball",
  "2": "Ultra Ball",
  "3": "Great Ball",
  "4": "Poké Ball",
  "5": "Safari Ball",
  "6": "Net Ball",
  "7": "Dive Ball",
  "8": "Nest Ball",
  "9": "Repeat Ball",
  "10": "Timer Ball",
  "11": "Luxury Ball",
  "12": "Premier Ball",
  "13": "Dusk Ball",
  "14": "Heal Ball",
  "15": "Quick Ball",
  "16": "Cherish Ball",
  "17": "Potion",
  "18": "Antidote",
  "19": "Burn Heal",
  "20": "Ice Heal",
  "21": "Awakening",
  "22": "Parlyz Heal",
  "23": "Full Restore",
  "24": "Max Potion",
  "25": "Hyper Potion",
  "26": "Super Potion",
  "27": "Full Heal",
  "28": "Revive",
  "29": "Max Revive",
  "30": "Fresh Water",
  "31": "Soda Pop",
  "32": "Lemonade",
  "33": "Moomoo Milk",
  "34": "EnergyPowder",
  "35": "Energy Root",
  "36": "Heal Powder",
  "37": "Revival Herb",
  "38": "Ether",
  "39": "Max Ether",
  "40": "Elixir",
  "41": "Max Elixir",
  "42": "Lava Cookie",
  "43": "Berry Juice",
  "44": "Sacred Ash",
  "45": "HP Up",
  "46": "Protein",
  "47": "Iron",
  "48": "Carbos",
  "49": "Calcium",
  "50": "Rare Candy",
  "51": "PP Up",
  "52": "Zinc",
  "53": "PP Max",
  "54": "Old Gateau",
  "55": "Guard Spec.",
  "56": "Dire Hit",
  "57": "X Attack",
  "58": "X Defend",
  "59": "X Speed",
  "60": "X Accuracy",
  "61": "X Special",
  "62": "X Sp. Def",
  "63": "Poké Doll",
  "64": "Fluffy Tail",
  "65": "Blue Flute",
  "66": "Yellow Flute",
  "67": "Red Flute",
  "68": "Black Flute",
  "69": "White Flute",
  "70": "Shoal Salt",
  "71": "Shoal Shell",
  "72": "Red Shard",
  "73": "Blue Shard",
  "74": "Yellow Shard",
  "75": "Green Shard",
  "76": "Super Repel",
  "77": "Max Repel",
  "78": "Escape Rope",
  "79": "Repel",
  "80": "Sun Stone",
  "81": "Moon Stone",
  "82": "Fire Stone",
  "83": "Thunderstone",
  "84": "Water Stone",
  "85": "Leaf Stone",
  "86": "TinyMushroom",
  "87": "Big Mushroom",
  "88": "Pearl",
  "89": "Big Pearl",
  "90": "Stardust",
  "91": "Star Piece",
  "92": "Nugget",
  "93": "Heart Scale",
  "94": "Honey",
  "95": "Growth Mulch",
  "96": "Damp Mulch",
  "97": "Stable Mulch",
  "98": "Gooey Mulch",
  "99": "Root Fossil",
  "100": "Claw Fossil",
  "101": "Helix Fossil",
  "102": "Dome Fossil",
  "103": "Old Amber",
  "104": "Armor Fossil",
  "105": "Skull Fossil",
  "106": "Rare Bone",
  "107": "Shiny Stone",
  "108": "Dusk Stone",
  "109": "Dawn Stone",
  "110": "Oval Stone",
  "111": "Odd Keystone",
  "112": "Griseous Orb",
  "116": "Douse Drive",
  "117": "Shock Drive",
  "118": "Burn Drive",
  "119": "Chill Drive",
  "134": "Sweet Heart",
  "135": "Adamant Orb",
  "136": "Lustrous Orb",
  "137": "Greet Mail",
  "138": "Favored Mail",
  "139": "RSVP Mail",
  "140": "Thanks Mail",
  "141": "Inquiry Mail",
  "142": "Like Mail",
  "143": "Reply Mail",
  "144": "BridgeMail S",
  "145": "BridgeMail D",
  "146": "BridgeMail T",
  "147": "BridgeMail V",
  "148": "BridgeMail M",
  "149": "Cheri Berry",
  "150": "Chesto Berry",
  "151": "Pecha Berry",
  "152": "Rawst Berry",
  "153": "Aspear Berry",
  "154": "Leppa Berry",
  "155": "Oran Berry",
  "156": "Persim Berry",
  "157": "Lum Berry",
  "158": "Sitrus Berry",
  "159": "Figy Berry",
  "160": "Wiki Berry",
  "161": "Mago Berry",
  "162": "Aguav Berry",
  "163": "Iapapa Berry",
  "164": "Razz Berry",
  "165": "Bluk Berry",
  "166": "Nanab Berry",
  "167": "Wepear Berry",
  "168": "Pinap Berry",
  "169": "Pomeg Berry",
  "170": "Kelpsy Berry",
  "171": "Qualot Berry",
  "172": "Hondew Berry",
  "173": "Grepa Berry",
  "174": "Tamato Berry",
  "175": "Cornn Berry",
  "176": "Magost Berry",
  "177": "Rabuta Berry",
  "178": "Nomel Berry",
  "179": "Spelon Berry",
  "180": "Pamtre Berry",
  "181": "Watmel Berry",
  "182": "Durin Berry",
  "183": "Belue Berry",
  "184": "Occa Berry",
  "185": "Passho Berry",
  "186": "Wacan Berry",
  "187": "Rindo Berry",
  "188": "Yache Berry",
  "189": "Chople Berry",
  "190": "Kebia Berry",
  "191": "Shuca Berry",
  "192": "Coba Berry",
  "193": "Payapa Berry",
  "194": "Tanga Berry",
  "195": "Charti Berry",
  "196": "Kasib Berry",
  "197": "Haban Berry",
  "198": "Colbur Berry",
  "199": "Babiri Berry",
  "200": "Chilan Berry",
  "201": "Liechi Berry",
  "202": "Ganlon Berry",
  "203": "Salac Berry",
  "204": "Petaya Berry",
  "205": "Apicot Berry",
  "206": "Lansat Berry",
  "207": "Starf Berry",
  "208": "Enigma Berry",
  "209": "Micle Berry",
  "210": "Custap Berry",
  "211": "Jaboca Berry",
  "212": "Rowap Berry",
  "213": "BrightPowder",
  "214": "White Herb",
  "215": "Macho Brace",
  "216": "Exp. Share",
  "217": "Quick Claw",
  "218": "Soothe Bell",
  "219": "Mental Herb",
  "220": "Choice Band",
  "221": "King's Rock",
  "222": "SilverPowder",
  "223": "Amulet Coin",
  "224": "Cleanse Tag",
  "225": "Soul Dew",
  "226": "DeepSeaTooth",
  "227": "DeepSeaScale",
  "228": "Smoke Ball",
  "229": "Everstone",
  "230": "Focus Band",
  "231": "Lucky Egg",
  "232": "Scope Lens",
  "233": "Metal Coat",
  "234": "Leftovers",
  "235": "Dragon Scale",
  "236": "Light Ball",
  "237": "Soft Sand",
  "238": "Hard Stone",
  "239": "Miracle Seed",
  "240": "BlackGlasses",
  "241": "Black Belt",
  "242": "Magnet",
  "243": "Mystic Water",
  "244": "Sharp Beak",
  "245": "Poison Barb",
  "246": "NeverMeltIce",
  "247": "Spell Tag",
  "248": "TwistedSpoon",
  "249": "Charcoal",
  "250": "Dragon Fang",
  "251": "Silk Scarf",
  "252": "Up-Grade",
  "253": "Shell Bell",
  "254": "Sea Incense",
  "255": "Lax Incense",
  "256": "Lucky Punch",
  "257": "Metal Powder",
  "258": "Thick Club",
  "259": "Stick",
  "260": "Red Scarf",
  "261": "Blue Scarf",
  "262": "Pink Scarf",
  "263": "Green Scarf",
  "264": "Yellow Scarf",
  "265": "Wide Lens",
  "266": "Muscle Band",
  "267": "Wise Glasses",
  "268": "Expert Belt",
  "269": "Light Clay",
  "270": "Life Orb",
  "271": "Power Herb",
  "272": "Toxic Orb",
  "273": "Flame Orb",
  "274": "Quick Powder",
  "275": "Focus Sash",
  "276": "Zoom Lens",
  "277": "Metronome",
  "278": "Iron Ball",
  "279": "Lagging Tail",
  "280": "Destiny Knot",
  "281": "Black Sludge",
  "282": "Icy Rock",
  "283": "Smooth Rock",
  "284": "Heat Rock",
  "285": "Damp Rock",
  "286": "Grip Claw",
  "287": "Choice Scarf",
  "288": "Sticky Barb",
  "289": "Power Bracer",
  "290": "Power Belt",
  "291": "Power Lens",
  "292": "Power Band",
  "293": "Power Anklet",
  "294": "Power Weight",
  "295": "Shed Shell",
  "296": "Big Root",
  "297": "Choice Specs",
  "298": "Flame Plate",
  "299": "Splash Plate",
  "300": "Zap Plate",
  "301": "Meadow Plate",
  "302": "Icicle Plate",
  "303": "Fist Plate",
  "304": "Toxic Plate",
  "305": "Earth Plate",
  "306": "Sky Plate",
  "307": "Mind Plate",
  "308": "Insect Plate",
  "309": "Stone Plate",
  "310": "Spooky Plate",
  "311": "Draco Plate",
  "312": "Dread Plate",
  "313": "Iron Plate",
  "314": "Odd Incense",
  "315": "Rock Incense",
  "316": "Full Incense",
  "317": "Wave Incense",
  "318": "Rose Incense",
  "319": "Luck Incense",
  "320": "Pure Incense",
  "321": "Protector",
  "322": "Electirizer",
  "323": "Magmarizer",
  "324": "Dubious Disc",
  "325": "Reaper Cloth",
  "326": "Razor Claw",
  "327": "Razor Fang",
  "328": "TM01",
  "329": "TM02",
  "330": "TM03",
  "331": "TM04",
  "332": "TM05",
  "333": "TM06",
  "334": "TM07",
  "335": "TM08",
  "336": "TM09",
  "337": "TM10",
  "338": "TM11",
  "339": "TM12",
  "340": "TM13",
  "341": "TM14",
  "342": "TM15",
  "343": "TM16",
  "344": "TM17",
  "345": "TM18",
  "346": "TM19",
  "347": "TM20",
  "348": "TM21",
  "349": "TM22",
  "350": "TM23",
  "351": "TM24",
  "352": "TM25",
  "353": "TM26",
  "354": "TM27",
  "355": "TM28",
  "356": "TM29",
  "357": "TM30",
  "358": "TM31",
  "359": "TM32",
  "360": "TM33",
  "361": "TM34",
  "362": "TM35",
  "363": "TM36",
  "364": "TM37",
  "365": "TM38",
  "366": "TM39",
  "367": "TM40",
  "368": "TM41",
  "369": "TM42",
  "370": "TM43",
  "371": "TM44",
  "372": "TM45",
  "373": "TM46",
  "374": "TM47",
  "375": "TM48",
  "376": "TM49",
  "377": "TM50",
  "378": "TM51",
  "379": "TM52",
  "380": "TM53",
  "381": "TM54",
  "382": "TM55",
  "383": "TM56",
  "384": "TM57",
  "385": "TM58",
  "386": "TM59",
  "387": "TM60",
  "388": "TM61",
  "389": "TM62",
  "390": "TM63",
  "391": "TM64",
  "392": "TM65",
  "393": "TM66",
  "394": "TM67",
  "395": "TM68",
  "396": "TM69",
  "397": "TM70",
  "398": "TM71",
  "399": "TM72",
  "400": "TM73",
  "401": "TM74",
  "402": "TM75",
  "403": "TM76",
  "404": "TM77",
  "405": "TM78",
  "406": "TM79",
  "407": "TM80",
  "408": "TM81",
  "409": "TM82",
  "410": "TM83",
  "411": "TM84",
  "412": "TM85",
  "413": "TM86",
  "414": "TM87",
  "415": "TM88",
  "416": "TM89",
  "417": "TM90",
  "418": "TM91",
  "419": "TM92",
  "420": "HM01",
  "421": "HM02",
  "422": "HM03",
  "423": "HM04",
  "424": "HM05",
  "425": "HM06",
  "428": "Explorer Kit",
  "429": "Loot Sack",
  "430": "Rule Book",
  "431": "Poké Radar",
  "432": "Point Card",
  "433": "Journal",
  "434": "Seal Case",
  "435": "Fashion Case",
  "436": "Seal Bag",
  "437": "Pal Pad",
  "438": "Works Key",
  "439": "Old Charm",
  "440": "Galactic Key",
  "441": "Red Chain",
  "442": "Town Map",
  "443": "Vs. Seeker",
  "444": "Coin Case",
  "445": "Old Rod",
  "446": "Good Rod",
  "447": "Super Rod",
  "448": "Sprayduck",
  "449": "Poffin Case",
  "450": "Bicycle",
  "451": "Suite Key",
  "452": "Oak's Letter",
  "453": "Lunar Wing",
  "454": "Member Card",
  "455": "Azure Flute",
  "456": "S.S. Ticket",
  "457": "Contest Pass",
  "458": "Magma Stone",
  "459": "Parcel",
  "460": "Coupon 1",
  "461": "Coupon 2",
  "462": "Coupon 3",
  "463": "Storage Key",
  "464": "SecretPotion",
  "465": "Vs. Recorder",
  "466": "Gracidea",
  "467": "Secret Key",
  "468": "Apricorn Box",
  "469": "Unown Report",
  "470": "Berry Pots",
  "471": "Dowsing MCHN",
  "472": "Blue Card",
  "473": "SlowpokeTail",
  "474": "Clear Bell",
  "475": "Card Key",
  "476": "Basement Key",
  "477": "SquirtBottle",
  "478": "Red Scale",
  "479": "Lost Item",
  "480": "Pass",
  "481": "Machine Part",
  "482": "Silver Wing",
  "483": "Rainbow Wing",
  "484": "Mystery Egg",
  "485": "Red Apricorn",
  "486": "Blu Apricorn",
  "487": "Ylw Apricorn",
  "488": "Grn Apricorn",
  "489": "Pnk Apricorn",
  "490": "Wht Apricorn",
  "491": "Blk Apricorn",
  "492": "Fast Ball",
  "493": "Level Ball",
  "494": "Lure Ball",
  "495": "Heavy Ball",
  "496": "Love Ball",
  "497": "Friend Ball",
  "498": "Moon Ball",
  "499": "Sport Ball",
  "500": "Park Ball",
  "501": "Photo Album",
  "502": "GB Sounds",
  "503": "Tidal Bell",
  "504": "RageCandyBar",
  "505": "Data Card 01",
  "506": "Data Card 02",
  "507": "Data Card 03",
  "508": "Data Card 04",
  "509": "Data Card 05",
  "510": "Data Card 06",
  "511": "Data Card 07",
  "512": "Data Card 08",
  "513": "Data Card 09",
  "514": "Data Card 10",
  "515": "Data Card 11",
  "516": "Data Card 12",
  "517": "Data Card 13",
  "518": "Data Card 14",
  "519": "Data Card 15",
  "520": "Data Card 16",
  "521": "Data Card 17",
  "522": "Data Card 18",
  "523": "Data Card 19",
  "524": "Data Card 20",
  "525": "Data Card 21",
  "526": "Data Card 22",
  "527": "Data Card 23",
  "528": "Data Card 24",
  "529": "Data Card 25",
  "530": "Data Card 26",
  "531": "Data Card 27",
  "532": "Jade Orb",
  "533": "Lock Capsule",
  "534": "Red Orb",
  "535": "Blue Orb",
  "536": "Enigma Stone",
  "537": "Prism Scale",
  "538": "Eviolite",
  "539": "Float Stone",
  "540": "Rocky Helmet",
  "541": "Air Balloon",
  "542": "Red Card",
  "543": "Ring Target",
  "544": "Binding Band",
  "545": "Absorb Bulb",
  "546": "Cell Battery",
  "547": "Eject Button",
  "548": "Fire Gem",
  "549": "Water Gem",
  "550": "Electric Gem",
  "551": "Grass Gem",
  "552": "Ice Gem",
  "553": "Fighting Gem",
  "554": "Poison Gem",
  "555": "Ground Gem",
  "556": "Flying Gem",
  "557": "Psychic Gem",
  "558": "Bug Gem",
  "559": "Rock Gem",
  "560": "Ghost Gem",
  "561": "Dragon Gem",
  "562": "Dark Gem",
  "563": "Steel Gem",
  "564": "Normal Gem",
  "565": "Health Wing",
  "566": "Muscle Wing",
  "567": "Resist Wing",
  "568": "Genius Wing",
  "569": "Clever Wing",
  "570": "Swift Wing",
  "571": "Pretty Wing",
  "572": "Cover Fossil",
  "573": "Plume Fossil",
  "574": "Liberty Pass",
  "575": "Pass Orb",
  "576": "Dream Ball",
  "577": "Poké Toy",
  "578": "Prop Case",
  "579": "Dragon Skull",
  "580": "BalmMushroom",
  "581": "Big Nugget",
  "582": "Pearl String",
  "583": "Comet Shard",
  "584": "Relic Copper",
  "585": "Relic Silver",
  "586": "Relic Gold",
  "587": "Relic Vase",
  "588": "Relic Band",
  "589": "Relic Statue",
  "590": "Relic Crown",
  "591": "Casteliacone",
  "592": "Dire Hit 2",
  "593": "X Speed 2",
  "594": "X Special 2",
  "595": "X Sp. Def 2",
  "596": "X Defend 2",
  "597": "X Attack 2",
  "598": "X Accuracy 2",
  "599": "X Speed 3",
  "600": "X Special 3",
  "601": "X Sp. Def 3",
  "602": "X Defend 3",
  "603": "X Attack 3",
  "604": "X Accuracy 3",
  "605": "X Speed 6",
  "606": "X Special 6",
  "607": "X Sp. Def 6",
  "608": "X Defend 6",
  "609": "X Attack 6",
  "610": "X Accuracy 6",
  "611": "Ability Urge",
  "612": "Item Drop",
  "613": "Item Urge",
  "614": "Reset Urge",
  "615": "Dire Hit 3",
  "616": "Light Stone",
  "617": "Dark Stone",
  "618": "TM93",
  "619": "TM94",
  "620": "TM95",
  "621": "Xtransceiver",
  "622": "God Stone",
  "623": "Gram 1",
  "624": "Gram 2",
  "625": "Gram 3",
  "626": "Xtransceiver",
  "627": "Medal Box",
  "628": "DNA Splicers",
  "629": "DNA Splicers",
  "630": "Permit",
  "631": "Oval Charm",
  "632": "Shiny Charm",
  "633": "Plasma Card",
  "634": "Grubby Hanky",
  "635": "Colress MCHN",
  "636": "Dropped Item",
  "637": "Dropped Item",
  "638": "Reveal Glass"
}
