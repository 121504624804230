(function() {
  var Pokemon, textEncoding;

  Pokemon = require('./pokemon');

  textEncoding = require('./text-encoding');

  exports.PcStorage = (function() {
    function PcStorage(buffer) {
      this.read(buffer);
    }

    PcStorage.prototype.read = function(buffer) {
      var boxBuf, i, name, wallpaper;
      this.currentBoxIndex = buffer.readUInt32LE(0x0000);
      return this.boxes = (function() {
        var _i, _results;
        _results = [];
        for (i = _i = 0; _i < 14; i = ++_i) {
          boxBuf = buffer.slice(0x0004 + i * 80 * 30, 0x0004 + (i + 1) * 80 * 30);
          name = textEncoding.decode(buffer.slice(0x8344 + i * 9, 0x8344 + (i + 1) * 9));
          wallpaper = buffer.readUInt8(0x83C2 + i);
          _results.push(new exports.Box(name, wallpaper, boxBuf));
        }
        return _results;
      })();
    };

    return PcStorage;

  })();

  exports.Box = (function() {
    function Box(name, wallpaper, buffer) {
      this.name = name;
      this.wallpaper = wallpaper;
      this.read(buffer);
    }

    Box.prototype.read = function(buffer) {
      var i;
      return this.pokemon = (function() {
        var _i, _results;
        _results = [];
        for (i = _i = 0; _i < 30; i = ++_i) {
          _results.push(Pokemon.fromBuffer(buffer.slice(i * 80, (i + 1) * 80)));
        }
        return _results;
      })();
    };

    Box.calcPos = function(x, y) {
      return y * 30 + x;
    };

    Box.prototype.at = function(x, y) {
      if (y == null) {
        y = null;
      }
      if (y != null) {
        x = exports.Box.calcPos(x, y);
      }
      return this.pokemon[x];
    };

    Box.prototype.setAt = function(x, y, pkmn) {
      var _ref;
      if (y == null) {
        y = null;
      }
      if (pkmn == null) {
        _ref = [y, null], pkmn = _ref[0], y = _ref[1];
      }
      if (y != null) {
        x = exports.Box.calcPos(x, y);
      }
      return this.pokemon[x] = pkmn;
    };

    return Box;

  })();

}).call(this);
