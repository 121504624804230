(function() {
  var _,
    __slice = [].slice;

  _ = require('lodash');

  exports.to1DArray = function(array) {
    return _.flatten([].concat(array));
  };

  exports.alias = function() {
    var dest, destProps, obj, srcProp, _i, _len, _results;
    obj = arguments[0], srcProp = arguments[1], destProps = 3 <= arguments.length ? __slice.call(arguments, 2) : [];
    destProps = exports.to1DArray(destProps);
    _results = [];
    for (_i = 0, _len = destProps.length; _i < _len; _i++) {
      dest = destProps[_i];
      _results.push(Object.defineProperty(obj, dest, {
        enumerable: false,
        get: function() {
          return this[srcProp];
        },
        set: function(val) {
          return this[srcProp] = val;
        }
      }));
    }
    return _results;
  };

}).call(this);
