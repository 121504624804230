(function() {
  module.exports = {
    'ruby-sapphire': {
      teamSize: 0x0234,
      teamStart: 0x0238,
      money: 0x0490,
      inventoryStart: 0x0498,
      inventorySizes: {
        pc: 50,
        item: 20,
        keyItem: 20,
        ball: 16,
        tmHm: 64,
        berry: 46
      }
    },
    'emerald': {
      teamSize: 0x0234,
      teamStart: 0x0238,
      money: 0x0490,
      pcItems: 0x0498,
      inventoryStart: 0x0498,
      inventorySizes: {
        pc: 50,
        item: 30,
        keyItem: 30,
        ball: 16,
        tmHm: 64,
        berry: 46
      }
    },
    'firered-leafgreen': {
      teamSize: 0x0034,
      teamStart: 0x0038,
      money: 0x0290,
      inventoryStart: 0x0298,
      inventorySizes: {
        pc: 30,
        item: 42,
        keyItem: 30,
        ball: 13,
        tmHm: 58,
        berry: 43
      }
    }
  };

}).call(this);
