{
  "1": "Stench",
  "2": "Drizzle",
  "3": "Speed Boost",
  "4": "Battle Armor",
  "5": "Sturdy",
  "6": "Damp",
  "7": "Limber",
  "8": "Sand Veil",
  "9": "Static",
  "10": "Volt Absorb",
  "11": "Water Absorb",
  "12": "Oblivious",
  "13": "Cloud Nine",
  "14": "Compound Eyes",
  "15": "Insomnia",
  "16": "Color Change",
  "17": "Immunity",
  "18": "Flash Fire",
  "19": "Shield Dust",
  "20": "Own Tempo",
  "21": "Suction Cups",
  "22": "Intimidate",
  "23": "Shadow Tag",
  "24": "Rough Skin",
  "25": "Wonder Guard",
  "26": "Levitate",
  "27": "Effect Spore",
  "28": "Synchronize",
  "29": "Clear Body",
  "30": "Natural Cure",
  "31": "Lightning Rod",
  "32": "Serene Grace",
  "33": "Swift Swim",
  "34": "Chlorophyll",
  "35": "Illuminate",
  "36": "Trace",
  "37": "Huge Power",
  "38": "Poison Point",
  "39": "Inner Focus",
  "40": "Magma Armor",
  "41": "Water Veil",
  "42": "Magnet Pull",
  "43": "Soundproof",
  "44": "Rain Dish",
  "45": "Sand Stream",
  "46": "Pressure",
  "47": "Thick Fat",
  "48": "Early Bird",
  "49": "Flame Body",
  "50": "Run Away",
  "51": "Keen Eye",
  "52": "Hyper Cutter",
  "53": "Pickup",
  "54": "Truant",
  "55": "Hustle",
  "56": "Cute Charm",
  "57": "Plus",
  "58": "Minus",
  "59": "Forecast",
  "60": "Sticky Hold",
  "61": "Shed Skin",
  "62": "Guts",
  "63": "Marvel Scale",
  "64": "Liquid Ooze",
  "65": "Overgrow",
  "66": "Blaze",
  "67": "Torrent",
  "68": "Swarm",
  "69": "Rock Head",
  "70": "Drought",
  "71": "Arena Trap",
  "72": "Vital Spirit",
  "73": "White Smoke",
  "74": "Pure Power",
  "75": "Shell Armor",
  "76": "Air Lock",
  "77": "Tangled Feet",
  "78": "Motor Drive",
  "79": "Rivalry",
  "80": "Steadfast",
  "81": "Snow Cloak",
  "82": "Gluttony",
  "83": "Anger Point",
  "84": "Unburden",
  "85": "Heatproof",
  "86": "Simple",
  "87": "Dry Skin",
  "88": "Download",
  "89": "Iron Fist",
  "90": "Poison Heal",
  "91": "Adaptability",
  "92": "Skill Link",
  "93": "Hydration",
  "94": "Solar Power",
  "95": "Quick Feet",
  "96": "Normalize",
  "97": "Sniper",
  "98": "Magic Guard",
  "99": "No Guard",
  "100": "Stall",
  "101": "Technician",
  "102": "Leaf Guard",
  "103": "Klutz",
  "104": "Mold Breaker",
  "105": "Super Luck",
  "106": "Aftermath",
  "107": "Anticipation",
  "108": "Forewarn",
  "109": "Unaware",
  "110": "Tinted Lens",
  "111": "Filter",
  "112": "Slow Start",
  "113": "Scrappy",
  "114": "Storm Drain",
  "115": "Ice Body",
  "116": "Solid Rock",
  "117": "Snow Warning",
  "118": "Honey Gather",
  "119": "Frisk",
  "120": "Reckless",
  "121": "Multitype",
  "122": "Flower Gift",
  "123": "Bad Dreams"
}
