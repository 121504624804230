// This is a generated file! Please edit source .ksy file and use kaitai-struct-compiler to rebuild

(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define(['kaitai-struct/KaitaiStream', 'pokesav/StringDecode', 'pokesav/TrainerCardSignatureReorder', 'pokesav/PokemonDecrypt', 'pokesav/PokemonDecryptShuffle'], factory);
  } else if (typeof module === 'object' && module.exports) {
    module.exports = factory(require('kaitai-struct/KaitaiStream'), require('../lib/decrypt-process.js').StringDecode, require('../lib/decrypt-process.js').TrainerCardSignatureReorder, require('../lib/decrypt-process.js').PokemonDecrypt, require('../lib/decrypt-process.js').PokemonDecryptShuffle);
  } else {
    root.PokesavDsGen5 = factory(root.KaitaiStream, root.StringDecode, root.TrainerCardSignatureReorder, root.PokemonDecrypt, root.PokemonDecryptShuffle);
  }
}(this, function (KaitaiStream, StringDecode, TrainerCardSignatureReorder, PokemonDecrypt, PokemonDecryptShuffle) {
var PokesavDsGen5 = (function() {
  PokesavDsGen5.PokemonBlockOrder = Object.freeze({
    ABCD: 0,
    ABDC: 1,
    ACBD: 2,
    ACDB: 3,
    ADBC: 4,
    ADCB: 5,
    BACD: 6,
    BADC: 7,
    BCAD: 8,
    BCDA: 9,
    BDAC: 10,
    BDCA: 11,
    CABD: 12,
    CADB: 13,
    CBAD: 14,
    CBDA: 15,
    CDAB: 16,
    CDBA: 17,
    DABC: 18,
    DACB: 19,
    DBAC: 20,
    DBCA: 21,
    DCAB: 22,
    DCBA: 23,

    0: "ABCD",
    1: "ABDC",
    2: "ACBD",
    3: "ACDB",
    4: "ADBC",
    5: "ADCB",
    6: "BACD",
    7: "BADC",
    8: "BCAD",
    9: "BCDA",
    10: "BDAC",
    11: "BDCA",
    12: "CABD",
    13: "CADB",
    14: "CBAD",
    15: "CBDA",
    16: "CDAB",
    17: "CDBA",
    18: "DABC",
    19: "DACB",
    20: "DBAC",
    21: "DBCA",
    22: "DCAB",
    23: "DCBA",
  });

  PokesavDsGen5.TrainerGender = Object.freeze({
    MALE: 0,
    FEMALE: 1,

    0: "MALE",
    1: "FEMALE",
  });

  PokesavDsGen5.PokemonInverseBlockOrder = Object.freeze({
    ABCD: 0,
    ABDC: 1,
    ACBD: 2,
    ADBC: 3,
    ACDB: 4,
    ADCB: 5,
    BACD: 6,
    BADC: 7,
    CABD: 8,
    DABC: 9,
    CADB: 10,
    DACB: 11,
    BCAD: 12,
    BDAC: 13,
    CBAD: 14,
    DBAC: 15,
    CDAB: 16,
    DCAB: 17,
    BCDA: 18,
    BDCA: 19,
    CBDA: 20,
    DBCA: 21,
    CDBA: 22,
    DCBA: 23,

    0: "ABCD",
    1: "ABDC",
    2: "ACBD",
    3: "ADBC",
    4: "ACDB",
    5: "ADCB",
    6: "BACD",
    7: "BADC",
    8: "CABD",
    9: "DABC",
    10: "CADB",
    11: "DACB",
    12: "BCAD",
    13: "BDAC",
    14: "CBAD",
    15: "DBAC",
    16: "CDAB",
    17: "DCAB",
    18: "BCDA",
    19: "BDCA",
    20: "CBDA",
    21: "DBCA",
    22: "CDBA",
    23: "DCBA",
  });

  PokesavDsGen5.Pokeball = Object.freeze({
    MASTER_BALL: 1,
    ULTRA_BALL: 2,
    GREAT_BALL: 3,
    POKE_BALL: 4,
    SAFARI_BALL: 5,
    NET_BALL: 6,
    DIVE_BALL: 7,
    NEST_BALL: 8,
    REPEAT_BALL: 9,
    TIMER_BALL: 10,
    LUXURY_BALL: 11,
    PREMIER_BALL: 12,
    DUSK_BALL: 13,
    HEAL_BALL: 14,
    QUICK_BALL: 15,
    CHERISH_BALL: 16,

    1: "MASTER_BALL",
    2: "ULTRA_BALL",
    3: "GREAT_BALL",
    4: "POKE_BALL",
    5: "SAFARI_BALL",
    6: "NET_BALL",
    7: "DIVE_BALL",
    8: "NEST_BALL",
    9: "REPEAT_BALL",
    10: "TIMER_BALL",
    11: "LUXURY_BALL",
    12: "PREMIER_BALL",
    13: "DUSK_BALL",
    14: "HEAL_BALL",
    15: "QUICK_BALL",
    16: "CHERISH_BALL",
  });

  PokesavDsGen5.MultiplayerAvatar = Object.freeze({
    NONE: 0,
    ACE_TRAINER_MALE: 1,
    PKMN_RANGER_MALE: 2,
    PKMN_BREEDER_MALE: 3,
    SCIENTIST_MALE: 4,
    HIKER: 5,
    ROUGHNECK: 6,
    PRESCHOOLER_MALE: 7,
    LASS: 8,
    ACE_TRAINER_FEMALE: 9,
    PKMN_RANGER_FEMALE: 10,
    PKMN_BREEDER_FEMALE: 11,
    SCIENTIST_FEMALE: 12,
    PARASOL_LADY: 13,
    NURSE: 14,
    PRESCHOOLER_FEMALE: 15,

    0: "NONE",
    1: "ACE_TRAINER_MALE",
    2: "PKMN_RANGER_MALE",
    3: "PKMN_BREEDER_MALE",
    4: "SCIENTIST_MALE",
    5: "HIKER",
    6: "ROUGHNECK",
    7: "PRESCHOOLER_MALE",
    8: "LASS",
    9: "ACE_TRAINER_FEMALE",
    10: "PKMN_RANGER_FEMALE",
    11: "PKMN_BREEDER_FEMALE",
    12: "SCIENTIST_FEMALE",
    13: "PARASOL_LADY",
    14: "NURSE",
    15: "PRESCHOOLER_FEMALE",
  });

  PokesavDsGen5.CountryOfOrigin = Object.freeze({
    JAPANESE: 1,
    ENGLISH: 2,
    FRENCH: 3,
    ITALIAN: 4,
    GERMAN: 5,
    SPANISH: 7,
    KOREAN: 8,

    1: "JAPANESE",
    2: "ENGLISH",
    3: "FRENCH",
    4: "ITALIAN",
    5: "GERMAN",
    7: "SPANISH",
    8: "KOREAN",
  });

  PokesavDsGen5.HgssPokeball = Object.freeze({
    FAST_BALL: 236,
    LEVEL_BALL: 237,
    LURE_BALL: 238,
    HEAVY_BALL: 239,
    LOVE_BALL: 240,
    FRIEND_BALL: 241,
    MOON_BALL: 242,
    COMPETITION_BALL: 243,
    PARK_BALL: 244,

    236: "FAST_BALL",
    237: "LEVEL_BALL",
    238: "LURE_BALL",
    239: "HEAVY_BALL",
    240: "LOVE_BALL",
    241: "FRIEND_BALL",
    242: "MOON_BALL",
    243: "COMPETITION_BALL",
    244: "PARK_BALL",
  });

  PokesavDsGen5.Nature = Object.freeze({
    HARDY: 0,
    LONELY: 1,
    BRAVE: 2,
    ADAMANT: 3,
    NAUGHTY: 4,
    BOLD: 5,
    DOCILE: 6,
    RELAXED: 7,
    IMPISH: 8,
    LAX: 9,
    TIMID: 10,
    HASTY: 11,
    SERIOUS: 12,
    JOLLY: 13,
    NAIVE: 14,
    MODEST: 15,
    MILD: 16,
    QUIET: 17,
    BASHFUL: 18,
    RASH: 19,
    CALM: 20,
    GENTLE: 21,
    SASSY: 22,
    CAREFUL: 23,
    QUIRKY: 24,

    0: "HARDY",
    1: "LONELY",
    2: "BRAVE",
    3: "ADAMANT",
    4: "NAUGHTY",
    5: "BOLD",
    6: "DOCILE",
    7: "RELAXED",
    8: "IMPISH",
    9: "LAX",
    10: "TIMID",
    11: "HASTY",
    12: "SERIOUS",
    13: "JOLLY",
    14: "NAIVE",
    15: "MODEST",
    16: "MILD",
    17: "QUIET",
    18: "BASHFUL",
    19: "RASH",
    20: "CALM",
    21: "GENTLE",
    22: "SASSY",
    23: "CAREFUL",
    24: "QUIRKY",
  });

  PokesavDsGen5.Game = Object.freeze({
    UNKNOWN: 0,
    HEART_GOLD_SOUL_SILVER: 8,
    DIAMOND_PEARL: 10,
    PLATINUM: 12,
    BLACK_WHITE: 20,
    BLACK_2_WHITE_2: 22,

    0: "UNKNOWN",
    8: "HEART_GOLD_SOUL_SILVER",
    10: "DIAMOND_PEARL",
    12: "PLATINUM",
    20: "BLACK_WHITE",
    22: "BLACK_2_WHITE_2",
  });

  function PokesavDsGen5(_io, _parent, _root) {
    this._io = _io;
    this._parent = _parent;
    this._root = _root || this;

    this._read();
  }
  PokesavDsGen5.prototype._read = function() {
  }

  var PartyPokemonBlock = PokesavDsGen5.PartyPokemonBlock = (function() {
    function PartyPokemonBlock(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PartyPokemonBlock.prototype._read = function() {
      this.junk = this._io.readBytes(4);
      this.partyPokemonCount = this._io.readU4le();
      this.partyPokemon = new Array((this.partyPokemonCount < 6 ? this.partyPokemonCount : 6));
      for (var i = 0; i < (this.partyPokemonCount < 6 ? this.partyPokemonCount : 6); i++) {
        this.partyPokemon[i] = new PokemonInParty(this._io, this, this._root);
      }
    }
    Object.defineProperty(PartyPokemonBlock.prototype, 'checksum', {
      get: function() {
        if (this._m_checksum !== undefined)
          return this._m_checksum;
        var _pos = this._io.pos;
        this._io.seek(1334);
        this._m_checksum = this._io.readU2le();
        this._io.seek(_pos);
        return this._m_checksum;
      }
    });

    return PartyPokemonBlock;
  })();

  var TrainerDataBlock = PokesavDsGen5.TrainerDataBlock = (function() {
    function TrainerDataBlock(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    TrainerDataBlock.prototype._read = function() {
      this.junk = this._io.readBytes(4);
      this._raw_trainerName = this._io.readBytes((2 * 8));
      var _process = new StringDecode();
      this.trainerName = _process.decode(this._raw_trainerName);
      this.trainerId = this._io.readU2le();
      this.secretId = this._io.readU2le();
    }
    Object.defineProperty(TrainerDataBlock.prototype, 'totalPlaytimeSeconds', {
      get: function() {
        if (this._m_totalPlaytimeSeconds !== undefined)
          return this._m_totalPlaytimeSeconds;
        this._m_totalPlaytimeSeconds = ((((this.playtime.hours * 60) * 60) + (this.playtime.minutes * 60)) + this.playtime.seconds);
        return this._m_totalPlaytimeSeconds;
      }
    });
    Object.defineProperty(TrainerDataBlock.prototype, 'multiplayerAvatar', {
      get: function() {
        if (this._m_multiplayerAvatar !== undefined)
          return this._m_multiplayerAvatar;
        var _pos = this._io.pos;
        this._io.seek(32);
        this._m_multiplayerAvatar = this._io.readU1();
        this._io.seek(_pos);
        return this._m_multiplayerAvatar;
      }
    });
    Object.defineProperty(TrainerDataBlock.prototype, 'trainerGender', {
      get: function() {
        if (this._m_trainerGender !== undefined)
          return this._m_trainerGender;
        var _pos = this._io.pos;
        this._io.seek(33);
        this._m_trainerGender = this._io.readU1();
        this._io.seek(_pos);
        return this._m_trainerGender;
      }
    });
    Object.defineProperty(TrainerDataBlock.prototype, 'checksum', {
      get: function() {
        if (this._m_checksum !== undefined)
          return this._m_checksum;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isBlack2White2 ? 178 : 106));
        this._m_checksum = this._io.readU2le();
        this._io.seek(_pos);
        return this._m_checksum;
      }
    });
    Object.defineProperty(TrainerDataBlock.prototype, 'playtime', {
      get: function() {
        if (this._m_playtime !== undefined)
          return this._m_playtime;
        var _pos = this._io.pos;
        this._io.seek(36);
        this._m_playtime = new Playtime(this._io, this, this._root);
        this._io.seek(_pos);
        return this._m_playtime;
      }
    });

    return TrainerDataBlock;
  })();

  var PokemonBlockD = PokesavDsGen5.PokemonBlockD = (function() {
    function PokemonBlockD(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonBlockD.prototype._read = function() {
      this._raw_originalTrainerName = this._io.readBytes((2 * 8));
      var _process = new StringDecode();
      this.originalTrainerName = _process.decode(this._raw_originalTrainerName);
      this.dateEggReceived = this._io.readBytes(3);
      this.dateMet = this._io.readBytes(3);
      this.diamondPearlEggLocation = this._io.readU2le();
      this.diamondPearlMetAtLocation = this._io.readU2le();
      this.pokerus = this._io.readU1();
      this.pokeball = this._io.readU1();
      this.originalTrainerGender = this._io.readBitsInt(1);
      this.metAtLevel = this._io.readBitsInt(7);
      this._io.alignToByte();
      this.encounterType = this._io.readU1();
      this.hgssPokeball = this._io.readU1();
      this.unused = this._io.readU1();
    }

    return PokemonBlockD;
  })();

  var CardSignatureBadgeBlock = PokesavDsGen5.CardSignatureBadgeBlock = (function() {
    function CardSignatureBadgeBlock(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    CardSignatureBadgeBlock.prototype._read = function() {
    }
    Object.defineProperty(CardSignatureBadgeBlock.prototype, 'trainerCardSignature', {
      get: function() {
        if (this._m_trainerCardSignature !== undefined)
          return this._m_trainerCardSignature;
        var _pos = this._io.pos;
        this._io.seek(0);
        this._raw__m_trainerCardSignature = this._io.readBytes(1536);
        var _process = new TrainerCardSignatureReorder(Math.floor(192 / 8));
        this._m_trainerCardSignature = _process.decode(this._raw__m_trainerCardSignature);
        this._io.seek(_pos);
        return this._m_trainerCardSignature;
      }
    });
    Object.defineProperty(CardSignatureBadgeBlock.prototype, 'trainerNature', {
      get: function() {
        if (this._m_trainerNature !== undefined)
          return this._m_trainerNature;
        var _pos = this._io.pos;
        this._io.seek(1536);
        this._m_trainerNature = this._io.readU1();
        this._io.seek(_pos);
        return this._m_trainerNature;
      }
    });

    return CardSignatureBadgeBlock;
  })();

  var PokemonBlockC = PokesavDsGen5.PokemonBlockC = (function() {
    function PokemonBlockC(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonBlockC.prototype._read = function() {
      this._raw_nickname = this._io.readBytes((2 * 11));
      var _process = new StringDecode();
      this.nickname = _process.decode(this._raw_nickname);
      this.unused = this._io.readU1();
      this.originGame = this._io.readU1();
      this.sinnohRibbonSet3 = this._io.readU2le();
      this.sinnohRibbonSet4 = this._io.readU2le();
      this.unused2 = this._io.readBytes(4);
    }

    return PokemonBlockC;
  })();

  var Playtime = PokesavDsGen5.Playtime = (function() {
    function Playtime(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    Playtime.prototype._read = function() {
      this.hours = this._io.readU2le();
      this.minutes = this._io.readU1();
      this.seconds = this._io.readU1();
    }

    return Playtime;
  })();

  var Evs = PokesavDsGen5.Evs = (function() {
    function Evs(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    Evs.prototype._read = function() {
      this.hp = this._io.readU1();
      this.attack = this._io.readU1();
      this.defense = this._io.readU1();
      this.speed = this._io.readU1();
      this.specialAttack = this._io.readU1();
      this.specialDefense = this._io.readU1();
    }

    return Evs;
  })();

  var Ivs = PokesavDsGen5.Ivs = (function() {
    function Ivs(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    Ivs.prototype._read = function() {
      this.flags = this._io.readU4le();
    }
    Object.defineProperty(Ivs.prototype, 'hp', {
      get: function() {
        if (this._m_hp !== undefined)
          return this._m_hp;
        this._m_hp = ((this.flags >>> 0) & 31);
        return this._m_hp;
      }
    });
    Object.defineProperty(Ivs.prototype, 'defense', {
      get: function() {
        if (this._m_defense !== undefined)
          return this._m_defense;
        this._m_defense = ((this.flags >>> 10) & 31);
        return this._m_defense;
      }
    });
    Object.defineProperty(Ivs.prototype, 'isNicknamed', {
      get: function() {
        if (this._m_isNicknamed !== undefined)
          return this._m_isNicknamed;
        this._m_isNicknamed = ((this.flags >>> 31) & 1) == 1;
        return this._m_isNicknamed;
      }
    });
    Object.defineProperty(Ivs.prototype, 'specialAttack', {
      get: function() {
        if (this._m_specialAttack !== undefined)
          return this._m_specialAttack;
        this._m_specialAttack = ((this.flags >>> 20) & 31);
        return this._m_specialAttack;
      }
    });
    Object.defineProperty(Ivs.prototype, 'isEgg', {
      get: function() {
        if (this._m_isEgg !== undefined)
          return this._m_isEgg;
        this._m_isEgg = ((this.flags >>> 30) & 1) == 1;
        return this._m_isEgg;
      }
    });
    Object.defineProperty(Ivs.prototype, 'attack', {
      get: function() {
        if (this._m_attack !== undefined)
          return this._m_attack;
        this._m_attack = ((this.flags >>> 5) & 31);
        return this._m_attack;
      }
    });
    Object.defineProperty(Ivs.prototype, 'specialDefense', {
      get: function() {
        if (this._m_specialDefense !== undefined)
          return this._m_specialDefense;
        this._m_specialDefense = ((this.flags >>> 25) & 31);
        return this._m_specialDefense;
      }
    });
    Object.defineProperty(Ivs.prototype, 'speed', {
      get: function() {
        if (this._m_speed !== undefined)
          return this._m_speed;
        this._m_speed = ((this.flags >>> 15) & 31);
        return this._m_speed;
      }
    });

    return Ivs;
  })();

  var PokemonBlockB = PokesavDsGen5.PokemonBlockB = (function() {
    function PokemonBlockB(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonBlockB.prototype._read = function() {
      this.moves = new Array(4);
      for (var i = 0; i < 4; i++) {
        this.moves[i] = this._io.readU2le();
      }
      this.movePps = new Array(4);
      for (var i = 0; i < 4; i++) {
        this.movePps[i] = this._io.readU1();
      }
      this.movePpUps = new Array(4);
      for (var i = 0; i < 4; i++) {
        this.movePpUps[i] = this._io.readU1();
      }
      this.iv = new Ivs(this._io, this, this._root);
      this.hoennRibbonSet1 = this._io.readU2le();
      this.hoennRibbonSet2 = this._io.readU2le();
      this.forme = this._io.readBitsInt(5);
      this.isGenderless = this._io.readBitsInt(1) != 0;
      this.isFemale = this._io.readBitsInt(1) != 0;
      this.fatefulEncounter = this._io.readBitsInt(1) != 0;
      this._io.alignToByte();
      this.nature = this._io.readU1();
      this.unused = this._io.readBitsInt(5);
      this.nsPokemon = this._io.readBitsInt(1) != 0;
      this.hasHiddenAbility = this._io.readBitsInt(1) != 0;
      this.unused2 = this._io.readBitsInt(1) != 0;
      this._io.alignToByte();
      this.unused3 = this._io.readBytes(1);
      this.platinumEggLocation = this._io.readU2le();
      this.platinumMetAtLocation = this._io.readU2le();
    }
    Object.defineProperty(PokemonBlockB.prototype, 'isEgg', {
      get: function() {
        if (this._m_isEgg !== undefined)
          return this._m_isEgg;
        this._m_isEgg = this.iv.isEgg;
        return this._m_isEgg;
      }
    });
    Object.defineProperty(PokemonBlockB.prototype, 'isNicknamed', {
      get: function() {
        if (this._m_isNicknamed !== undefined)
          return this._m_isNicknamed;
        this._m_isNicknamed = this.iv.isNicknamed;
        return this._m_isNicknamed;
      }
    });

    return PokemonBlockB;
  })();

  var PokemonBattleStats = PokesavDsGen5.PokemonBattleStats = (function() {
    function PokemonBattleStats(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonBattleStats.prototype._read = function() {
      this.asleepTurnCount = this._io.readBitsInt(3);
      this.isPoisoned = this._io.readBitsInt(1) != 0;
      this.isBurned = this._io.readBitsInt(1) != 0;
      this.isFrozen = this._io.readBitsInt(1) != 0;
      this.isParalyzed = this._io.readBitsInt(1) != 0;
      this.isToxic = this._io.readBitsInt(1) != 0;
      this._io.alignToByte();
      this.unknown = this._io.readBytes(3);
      this.level = this._io.readU1();
      this.capsuleIndex = this._io.readU1();
      this.currentHp = this._io.readU2le();
      this.stats = new PokemonStats(this._io, this, this._root);
      this.mailMessage = this._io.readBytes(55);
      this.unused = this._io.readBytes(8);
    }

    return PokemonBattleStats;
  })();

  var AdventureDataBlock = PokesavDsGen5.AdventureDataBlock = (function() {
    function AdventureDataBlock(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    AdventureDataBlock.prototype._read = function() {
    }
    Object.defineProperty(AdventureDataBlock.prototype, 'adventureStartTime', {
      get: function() {
        if (this._m_adventureStartTime !== undefined)
          return this._m_adventureStartTime;
        var _pos = this._io.pos;
        this._io.seek(52);
        this._m_adventureStartTime = this._io.readU4le();
        this._io.seek(_pos);
        return this._m_adventureStartTime;
      }
    });
    Object.defineProperty(AdventureDataBlock.prototype, 'leagueChampTime', {
      get: function() {
        if (this._m_leagueChampTime !== undefined)
          return this._m_leagueChampTime;
        var _pos = this._io.pos;
        this._io.seek(60);
        this._m_leagueChampTime = this._io.readU4le();
        this._io.seek(_pos);
        return this._m_leagueChampTime;
      }
    });

    return AdventureDataBlock;
  })();

  var PokemonData = PokesavDsGen5.PokemonData = (function() {
    function PokemonData(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonData.prototype._read = function() {
      this._raw_blockA = this._io.readBytes(32);
      var _io__raw_blockA = new KaitaiStream(this._raw_blockA);
      this.blockA = new PokemonBlockA(_io__raw_blockA, this, this._root);
      this._raw_blockB = this._io.readBytes(32);
      var _io__raw_blockB = new KaitaiStream(this._raw_blockB);
      this.blockB = new PokemonBlockB(_io__raw_blockB, this, this._root);
      this._raw_blockC = this._io.readBytes(32);
      var _io__raw_blockC = new KaitaiStream(this._raw_blockC);
      this.blockC = new PokemonBlockC(_io__raw_blockC, this, this._root);
      this._raw_blockD = this._io.readBytes(32);
      var _io__raw_blockD = new KaitaiStream(this._raw_blockD);
      this.blockD = new PokemonBlockD(_io__raw_blockD, this, this._root);
    }

    return PokemonData;
  })();

  var PokemonInParty = PokesavDsGen5.PokemonInParty = (function() {
    function PokemonInParty(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonInParty.prototype._read = function() {
      this.base = new Pokemon(this._io, this, this._root);
      this._raw__raw_battleStats = this._io.readBytes(84);
      var _process = new PokemonDecrypt(this.base.personalityValue);
      this._raw_battleStats = _process.decode(this._raw__raw_battleStats);
      var _io__raw_battleStats = new KaitaiStream(this._raw_battleStats);
      this.battleStats = new PokemonBattleStats(_io__raw_battleStats, this, this._root);
    }

    return PokemonInParty;
  })();

  var Pokemon = PokesavDsGen5.Pokemon = (function() {
    function Pokemon(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    Pokemon.prototype._read = function() {
      this.personalityValue = this._io.readU4le();
      this.junk = this._io.readBytes(2);
      this.checksum = this._io.readU2le();
      this._raw__raw_data = this._io.readBytes(128);
      var _process = new PokemonDecryptShuffle(this.blockOrder, this.checksum);
      this._raw_data = _process.decode(this._raw__raw_data);
      var _io__raw_data = new KaitaiStream(this._raw_data);
      this.data = new PokemonData(_io__raw_data, this, this._root);
    }
    Object.defineProperty(Pokemon.prototype, 'isShiny', {
      get: function() {
        if (this._m_isShiny !== undefined)
          return this._m_isShiny;
        this._m_isShiny = (((this.blockA.originalTrainerId ^ this.blockA.originalTrainerSecretId) ^ (this.personalityValue & 65535)) ^ ((this.personalityValue >>> 16) & 65535)) < 8;
        return this._m_isShiny;
      }
    });
    Object.defineProperty(Pokemon.prototype, 'blockOrder', {
      get: function() {
        if (this._m_blockOrder !== undefined)
          return this._m_blockOrder;
        this._m_blockOrder = KaitaiStream.mod(((this.personalityValue >>> 13) & 31), 24);
        return this._m_blockOrder;
      }
    });
    Object.defineProperty(Pokemon.prototype, 'blockC', {
      get: function() {
        if (this._m_blockC !== undefined)
          return this._m_blockC;
        this._m_blockC = this.data.blockC;
        return this._m_blockC;
      }
    });
    Object.defineProperty(Pokemon.prototype, 'blockD', {
      get: function() {
        if (this._m_blockD !== undefined)
          return this._m_blockD;
        this._m_blockD = this.data.blockD;
        return this._m_blockD;
      }
    });
    Object.defineProperty(Pokemon.prototype, 'blockB', {
      get: function() {
        if (this._m_blockB !== undefined)
          return this._m_blockB;
        this._m_blockB = this.data.blockB;
        return this._m_blockB;
      }
    });
    Object.defineProperty(Pokemon.prototype, 'blockA', {
      get: function() {
        if (this._m_blockA !== undefined)
          return this._m_blockA;
        this._m_blockA = this.data.blockA;
        return this._m_blockA;
      }
    });

    return Pokemon;
  })();

  var PokemonBlockA = PokesavDsGen5.PokemonBlockA = (function() {
    function PokemonBlockA(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonBlockA.prototype._read = function() {
      this.nationalPokedexId = this._io.readU2le();
      this.heldItem = this._io.readU2le();
      this.originalTrainerId = this._io.readU2le();
      this.originalTrainerSecretId = this._io.readU2le();
      this.experiencePoints = this._io.readU4le();
      this.friendship = this._io.readU1();
      this.ability = this._io.readU1();
      this.markings = this._io.readU1();
      this.originalLanguage = this._io.readU1();
      this.ev = new Evs(this._io, this, this._root);
      this.contestValues = this._io.readBytes(6);
      this.sinnohRibbonSet1 = this._io.readU2le();
      this.sinnohRibbonSet2 = this._io.readU2le();
    }

    return PokemonBlockA;
  })();

  var ExtraTrainerDataBlock = PokesavDsGen5.ExtraTrainerDataBlock = (function() {
    function ExtraTrainerDataBlock(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    ExtraTrainerDataBlock.prototype._read = function() {
      this.money = this._io.readU4le();
      this.badgeFlags = this._io.readU1();
    }

    return ExtraTrainerDataBlock;
  })();

  var ChecksumBlock = PokesavDsGen5.ChecksumBlock = (function() {
    function ChecksumBlock(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    ChecksumBlock.prototype._read = function() {
    }
    Object.defineProperty(ChecksumBlock.prototype, 'partyPokemonChecksum', {
      get: function() {
        if (this._m_partyPokemonChecksum !== undefined)
          return this._m_partyPokemonChecksum;
        var _pos = this._io.pos;
        this._io.seek(52);
        this._m_partyPokemonChecksum = this._io.readU2le();
        this._io.seek(_pos);
        return this._m_partyPokemonChecksum;
      }
    });
    Object.defineProperty(ChecksumBlock.prototype, 'trainerDataChecksum', {
      get: function() {
        if (this._m_trainerDataChecksum !== undefined)
          return this._m_trainerDataChecksum;
        var _pos = this._io.pos;
        this._io.seek(54);
        this._m_trainerDataChecksum = this._io.readU2le();
        this._io.seek(_pos);
        return this._m_trainerDataChecksum;
      }
    });

    return ChecksumBlock;
  })();

  var PokemonStats = PokesavDsGen5.PokemonStats = (function() {
    function PokemonStats(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonStats.prototype._read = function() {
      this.hp = this._io.readU2le();
      this.attack = this._io.readU2le();
      this.defense = this._io.readU2le();
      this.speed = this._io.readU2le();
      this.specialAttack = this._io.readU2le();
      this.specialDefense = this._io.readU2le();
    }

    return PokemonStats;
  })();
  Object.defineProperty(PokesavDsGen5.prototype, 'cardSignatureBadgeBlock', {
    get: function() {
      if (this._m_cardSignatureBadgeBlock !== undefined)
        return this._m_cardSignatureBadgeBlock;
      var _pos = this._io.pos;
      this._io.seek(114944);
      this._raw__m_cardSignatureBadgeBlock = this._io.readBytes(1628);
      var _io__raw__m_cardSignatureBadgeBlock = new KaitaiStream(this._raw__m_cardSignatureBadgeBlock);
      this._m_cardSignatureBadgeBlock = new CardSignatureBadgeBlock(_io__raw__m_cardSignatureBadgeBlock, this, this._root);
      this._io.seek(_pos);
      return this._m_cardSignatureBadgeBlock;
    }
  });
  Object.defineProperty(PokesavDsGen5.prototype, 'isBlackWhite', {
    get: function() {
      if (this._m_isBlackWhite !== undefined)
        return this._m_isBlackWhite;
      this._m_isBlackWhite = this.partyPokemonBlock.checksum == this.blackWhiteChecksumBlock.partyPokemonChecksum;
      return this._m_isBlackWhite;
    }
  });
  Object.defineProperty(PokesavDsGen5.prototype, 'partyPokemonBlock', {
    get: function() {
      if (this._m_partyPokemonBlock !== undefined)
        return this._m_partyPokemonBlock;
      var _pos = this._io.pos;
      this._io.seek(101888);
      this._raw__m_partyPokemonBlock = this._io.readBytes(1336);
      var _io__raw__m_partyPokemonBlock = new KaitaiStream(this._raw__m_partyPokemonBlock);
      this._m_partyPokemonBlock = new PartyPokemonBlock(_io__raw__m_partyPokemonBlock, this, this._root);
      this._io.seek(_pos);
      return this._m_partyPokemonBlock;
    }
  });
  Object.defineProperty(PokesavDsGen5.prototype, 'trainerDataBlock', {
    get: function() {
      if (this._m_trainerDataBlock !== undefined)
        return this._m_trainerDataBlock;
      var _pos = this._io.pos;
      this._io.seek(103424);
      this._raw__m_trainerDataBlock = this._io.readBytes((this.isBlack2White2 ? 180 : 108));
      var _io__raw__m_trainerDataBlock = new KaitaiStream(this._raw__m_trainerDataBlock);
      this._m_trainerDataBlock = new TrainerDataBlock(_io__raw__m_trainerDataBlock, this, this._root);
      this._io.seek(_pos);
      return this._m_trainerDataBlock;
    }
  });
  Object.defineProperty(PokesavDsGen5.prototype, 'isBlack2White2', {
    get: function() {
      if (this._m_isBlack2White2 !== undefined)
        return this._m_isBlack2White2;
      this._m_isBlack2White2 = this.partyPokemonBlock.checksum == this.black2White2ChecksumBlock.partyPokemonChecksum;
      return this._m_isBlack2White2;
    }
  });
  Object.defineProperty(PokesavDsGen5.prototype, 'extraTrainerDataBlock', {
    get: function() {
      if (this._m_extraTrainerDataBlock !== undefined)
        return this._m_extraTrainerDataBlock;
      var _pos = this._io.pos;
      this._io.seek((this.isBlack2White2 ? 135424 : 135680));
      this._raw__m_extraTrainerDataBlock = this._io.readBytes(236);
      var _io__raw__m_extraTrainerDataBlock = new KaitaiStream(this._raw__m_extraTrainerDataBlock);
      this._m_extraTrainerDataBlock = new ExtraTrainerDataBlock(_io__raw__m_extraTrainerDataBlock, this, this._root);
      this._io.seek(_pos);
      return this._m_extraTrainerDataBlock;
    }
  });
  Object.defineProperty(PokesavDsGen5.prototype, 'adventureDataBlock', {
    get: function() {
      if (this._m_adventureDataBlock !== undefined)
        return this._m_adventureDataBlock;
      var _pos = this._io.pos;
      this._io.seek(121088);
      this._raw__m_adventureDataBlock = this._io.readBytes(92);
      var _io__raw__m_adventureDataBlock = new KaitaiStream(this._raw__m_adventureDataBlock);
      this._m_adventureDataBlock = new AdventureDataBlock(_io__raw__m_adventureDataBlock, this, this._root);
      this._io.seek(_pos);
      return this._m_adventureDataBlock;
    }
  });
  Object.defineProperty(PokesavDsGen5.prototype, 'game', {
    get: function() {
      if (this._m_game !== undefined)
        return this._m_game;
      this._m_game = (this.isBlackWhite ? PokesavDsGen5.Game.BLACK_WHITE : (this.isBlack2White2 ? PokesavDsGen5.Game.BLACK_2_WHITE_2 : PokesavDsGen5.Game.UNKNOWN));
      return this._m_game;
    }
  });
  Object.defineProperty(PokesavDsGen5.prototype, 'blackWhiteChecksumBlock', {
    get: function() {
      if (this._m_blackWhiteChecksumBlock !== undefined)
        return this._m_blackWhiteChecksumBlock;
      var _pos = this._io.pos;
      this._io.seek(147200);
      this._raw__m_blackWhiteChecksumBlock = this._io.readBytes(140);
      var _io__raw__m_blackWhiteChecksumBlock = new KaitaiStream(this._raw__m_blackWhiteChecksumBlock);
      this._m_blackWhiteChecksumBlock = new ChecksumBlock(_io__raw__m_blackWhiteChecksumBlock, this, this._root);
      this._io.seek(_pos);
      return this._m_blackWhiteChecksumBlock;
    }
  });
  Object.defineProperty(PokesavDsGen5.prototype, 'black2White2ChecksumBlock', {
    get: function() {
      if (this._m_black2White2ChecksumBlock !== undefined)
        return this._m_black2White2ChecksumBlock;
      var _pos = this._io.pos;
      this._io.seek(155392);
      this._raw__m_black2White2ChecksumBlock = this._io.readBytes(147);
      var _io__raw__m_black2White2ChecksumBlock = new KaitaiStream(this._raw__m_black2White2ChecksumBlock);
      this._m_black2White2ChecksumBlock = new ChecksumBlock(_io__raw__m_black2White2ChecksumBlock, this, this._root);
      this._io.seek(_pos);
      return this._m_black2White2ChecksumBlock;
    }
  });

  return PokesavDsGen5;
})();
return PokesavDsGen5;
}));
