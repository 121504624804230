(function() {
  module.exports = {
    languages: {
      0x0201: 'japanese',
      0x0202: 'english',
      0x0203: 'french',
      0x0204: 'italian',
      0x0205: 'german',
      0x0206: 'korean',
      0x0207: 'spanish'
    }
  };

}).call(this);
