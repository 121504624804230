(function() {
  var GameSave, Item, PcStorage, Pokemon, Section, textEncoding, _;

  _ = require('lodash');

  Section = require('./section');

  Pokemon = require('./pokemon');

  Item = require('./item');

  PcStorage = require('./pc-boxes').PcStorage;

  textEncoding = require('./text-encoding');

  module.exports = GameSave = (function() {
    function GameSave(buffer) {
      this.saveIndex = -1;
      this.read(buffer);
      Object.defineProperties(this, {
        publicId: {
          enumerable: false,
          get: (function(_this) {
            return function() {
              return _this.trainerId & 0xFFFF;
            };
          })(this)
        },
        privateId: {
          enumerable: false,
          get: (function(_this) {
            return function() {
              return (_this.trainerId & 0xFFFF0000) >> 16;
            };
          })(this)
        }
      });
    }

    GameSave.prototype.read = function(buffer) {
      var i, section, sectionBuffer, sections, _i;
      sections = new Array(14);
      for (i = _i = 0; _i < 14; i = ++_i) {
        sectionBuffer = buffer.slice(i * Section.size, (i + 1) * Section.size);
        section = Section.split(sectionBuffer);
        sections[section.id] = section;
        this.saveIndex = section.saveIndex;
      }
      return this.readSections(sections);
    };

    GameSave.prototype.readSections = function(sections) {
      var data, gameCode, i, id, offsets, pcBuffer, pkmnData, readInventorySlot, section, teamData, teamSize, _i, _len, _results;
      pcBuffer = new Buffer(33744);
      _results = [];
      for (_i = 0, _len = sections.length; _i < _len; _i++) {
        section = sections[_i];
        id = section.id, data = section.data;
        switch (id) {
          case 0:
            this.name = textEncoding.decode(data.slice(0x00, 0x00 + 7));
            this.gender = (data.readUInt8(0x08)) === 0 ? 'male' : 'female';
            this.trainerId = data.readUInt32LE(0x0A);
            this.timePlayed = {
              hours: data.readUInt16LE(0x0E),
              minutes: data.readUInt8(0x0E + 2),
              seconds: data.readUInt8(0x0E + 3),
              frames: data.readUInt8(0x0E + 4)
            };
            gameCode = data.readUInt32LE(0xAC);
            switch (gameCode) {
              case 0:
                this.game = 'ruby-sapphire';
                _results.push(this.securityKey = 0x00);
                break;
              case 1:
                this.game = 'firered-leafgreen';
                _results.push(this.securityKey = data.readUInt32LE(0x0AF8));
                break;
              default:
                this.game = 'emerald';
                _results.push(this.securityKey = gameCode);
            }
            break;
          case 1:
            offsets = (require('./section1-offsets'))[this.game];
            teamSize = data.readUInt32LE(offsets.teamSize);
            teamData = data.slice(offsets.teamStart, offsets.teamStart + teamSize * 100);
            this.team = (function() {
              var _j, _results1;
              _results1 = [];
              for (i = _j = 0; 0 <= teamSize ? _j < teamSize : _j > teamSize; i = 0 <= teamSize ? ++_j : --_j) {
                pkmnData = teamData.slice(i * 100, (i + 1) * 100);
                _results1.push(new Pokemon(pkmnData));
              }
              return _results1;
            })();
            this.money = (data.readUInt32LE(offsets.money)) ^ this.securityKey;
            readInventorySlot = (function(_this) {
              return function(targetSlot, securityKey) {
                var offset, size, slot, slotOrder, _j, _len1;
                if (securityKey == null) {
                  securityKey = _this.securityKey;
                }
                slotOrder = ['pc', 'item', 'keyItem', 'ball', 'tmHm', 'berry'];
                offset = offsets.inventoryStart;
                for (_j = 0, _len1 = slotOrder.length; _j < _len1; _j++) {
                  slot = slotOrder[_j];
                  if (slot === targetSlot) {
                    break;
                  }
                  offset += offsets.inventorySizes[slot] * Item.itemEntryLength;
                }
                size = offsets.inventorySizes[targetSlot];
                return Item.readList(data, offset, size, securityKey);
              };
            })(this);
            this.pcItems = readInventorySlot('pc', 0);
            _results.push(this.inventory = {
              item: readInventorySlot('item'),
              keyItem: readInventorySlot('keyItem'),
              ball: readInventorySlot('ball'),
              tmHm: readInventorySlot('tmHm'),
              berry: readInventorySlot('berry')
            });
            break;
          case 4:
            if (this.game === 'firered-leafgreen') {
              _results.push(this.rivalName = textEncoding.decode(data.slice(0x0BCC, 0x0BCC + 8)));
            } else {
              _results.push(void 0);
            }
            break;
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
            _results.push(data.copy(pcBuffer, (id - 5) * 3968, 0, 3968));
            break;
          case 13:
            data.copy(pcBuffer, (id - 5) * 3968, 0, 2000);
            _results.push(this.pcPkmnStorage = new PcStorage(pcBuffer));
            break;
          default:
            _results.push(void 0);
        }
      }
      return _results;
    };

    return GameSave;

  })();

}).call(this);
