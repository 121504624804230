// This is a generated file! Please edit source .ksy file and use kaitai-struct-compiler to rebuild

(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define(['kaitai-struct/KaitaiStream', 'pokesav/StringDecode', 'pokesav/TrainerCardSignatureReorder', 'pokesav/PokemonDecrypt', 'pokesav/PokemonDecryptShuffle'], factory);
  } else if (typeof module === 'object' && module.exports) {
    module.exports = factory(require('kaitai-struct/KaitaiStream'), require('../lib/decrypt-process.js').StringDecode, require('../lib/decrypt-process.js').TrainerCardSignatureReorder, require('../lib/decrypt-process.js').PokemonDecrypt, require('../lib/decrypt-process.js').PokemonDecryptShuffle);
  } else {
    root.PokesavDsGen4 = factory(root.KaitaiStream, root.StringDecode, root.TrainerCardSignatureReorder, root.PokemonDecrypt, root.PokemonDecryptShuffle);
  }
}(this, function (KaitaiStream, StringDecode, TrainerCardSignatureReorder, PokemonDecrypt, PokemonDecryptShuffle) {
var PokesavDsGen4 = (function() {
  PokesavDsGen4.PokemonBlockOrder = Object.freeze({
    ABCD: 0,
    ABDC: 1,
    ACBD: 2,
    ACDB: 3,
    ADBC: 4,
    ADCB: 5,
    BACD: 6,
    BADC: 7,
    BCAD: 8,
    BCDA: 9,
    BDAC: 10,
    BDCA: 11,
    CABD: 12,
    CADB: 13,
    CBAD: 14,
    CBDA: 15,
    CDAB: 16,
    CDBA: 17,
    DABC: 18,
    DACB: 19,
    DBAC: 20,
    DBCA: 21,
    DCAB: 22,
    DCBA: 23,

    0: "ABCD",
    1: "ABDC",
    2: "ACBD",
    3: "ACDB",
    4: "ADBC",
    5: "ADCB",
    6: "BACD",
    7: "BADC",
    8: "BCAD",
    9: "BCDA",
    10: "BDAC",
    11: "BDCA",
    12: "CABD",
    13: "CADB",
    14: "CBAD",
    15: "CBDA",
    16: "CDAB",
    17: "CDBA",
    18: "DABC",
    19: "DACB",
    20: "DBAC",
    21: "DBCA",
    22: "DCAB",
    23: "DCBA",
  });

  PokesavDsGen4.TrainerGender = Object.freeze({
    MALE: 0,
    FEMALE: 1,

    0: "MALE",
    1: "FEMALE",
  });

  PokesavDsGen4.PokemonInverseBlockOrder = Object.freeze({
    ABCD: 0,
    ABDC: 1,
    ACBD: 2,
    ADBC: 3,
    ACDB: 4,
    ADCB: 5,
    BACD: 6,
    BADC: 7,
    CABD: 8,
    DABC: 9,
    CADB: 10,
    DACB: 11,
    BCAD: 12,
    BDAC: 13,
    CBAD: 14,
    DBAC: 15,
    CDAB: 16,
    DCAB: 17,
    BCDA: 18,
    BDCA: 19,
    CBDA: 20,
    DBCA: 21,
    CDBA: 22,
    DCBA: 23,

    0: "ABCD",
    1: "ABDC",
    2: "ACBD",
    3: "ADBC",
    4: "ACDB",
    5: "ADCB",
    6: "BACD",
    7: "BADC",
    8: "CABD",
    9: "DABC",
    10: "CADB",
    11: "DACB",
    12: "BCAD",
    13: "BDAC",
    14: "CBAD",
    15: "DBAC",
    16: "CDAB",
    17: "DCAB",
    18: "BCDA",
    19: "BDCA",
    20: "CBDA",
    21: "DBCA",
    22: "CDBA",
    23: "DCBA",
  });

  PokesavDsGen4.Pokeball = Object.freeze({
    MASTER_BALL: 1,
    ULTRA_BALL: 2,
    GREAT_BALL: 3,
    POKE_BALL: 4,
    SAFARI_BALL: 5,
    NET_BALL: 6,
    DIVE_BALL: 7,
    NEST_BALL: 8,
    REPEAT_BALL: 9,
    TIMER_BALL: 10,
    LUXURY_BALL: 11,
    PREMIER_BALL: 12,
    DUSK_BALL: 13,
    HEAL_BALL: 14,
    QUICK_BALL: 15,
    CHERISH_BALL: 16,

    1: "MASTER_BALL",
    2: "ULTRA_BALL",
    3: "GREAT_BALL",
    4: "POKE_BALL",
    5: "SAFARI_BALL",
    6: "NET_BALL",
    7: "DIVE_BALL",
    8: "NEST_BALL",
    9: "REPEAT_BALL",
    10: "TIMER_BALL",
    11: "LUXURY_BALL",
    12: "PREMIER_BALL",
    13: "DUSK_BALL",
    14: "HEAL_BALL",
    15: "QUICK_BALL",
    16: "CHERISH_BALL",
  });

  PokesavDsGen4.MultiplayerAvatar = Object.freeze({
    NONE: 0,
    SCHOOL_KID: 3,
    BUG_CATCHER: 5,
    LASS: 6,
    BATTLE_GIRL: 7,
    ACE_TRAINER_MALE: 11,
    BEAUTY: 13,
    ACE_TRAINER_FEMALE: 14,
    ROUGHNECK: 15,
    POP_IDOL: 31,
    SOCIAL: 35,
    COWGIRL: 37,
    RUIN_MANIAC: 42,
    BLACK_BELT: 50,
    RICH_BOY: 62,
    LADY: 63,
    PSYCHIC: 70,

    0: "NONE",
    3: "SCHOOL_KID",
    5: "BUG_CATCHER",
    6: "LASS",
    7: "BATTLE_GIRL",
    11: "ACE_TRAINER_MALE",
    13: "BEAUTY",
    14: "ACE_TRAINER_FEMALE",
    15: "ROUGHNECK",
    31: "POP_IDOL",
    35: "SOCIAL",
    37: "COWGIRL",
    42: "RUIN_MANIAC",
    50: "BLACK_BELT",
    62: "RICH_BOY",
    63: "LADY",
    70: "PSYCHIC",
  });

  PokesavDsGen4.CountryOfOrigin = Object.freeze({
    JAPANESE: 1,
    ENGLISH: 2,
    FRENCH: 3,
    ITALIAN: 4,
    GERMAN: 5,
    SPANISH: 7,
    KOREAN: 8,

    1: "JAPANESE",
    2: "ENGLISH",
    3: "FRENCH",
    4: "ITALIAN",
    5: "GERMAN",
    7: "SPANISH",
    8: "KOREAN",
  });

  PokesavDsGen4.HgssPokeball = Object.freeze({
    FAST_BALL: 236,
    LEVEL_BALL: 237,
    LURE_BALL: 238,
    HEAVY_BALL: 239,
    LOVE_BALL: 240,
    FRIEND_BALL: 241,
    MOON_BALL: 242,
    COMPETITION_BALL: 243,
    PARK_BALL: 244,

    236: "FAST_BALL",
    237: "LEVEL_BALL",
    238: "LURE_BALL",
    239: "HEAVY_BALL",
    240: "LOVE_BALL",
    241: "FRIEND_BALL",
    242: "MOON_BALL",
    243: "COMPETITION_BALL",
    244: "PARK_BALL",
  });

  PokesavDsGen4.Nature = Object.freeze({
    HARDY: 0,
    LONELY: 1,
    BRAVE: 2,
    ADAMANT: 3,
    NAUGHTY: 4,
    BOLD: 5,
    DOCILE: 6,
    RELAXED: 7,
    IMPISH: 8,
    LAX: 9,
    TIMID: 10,
    HASTY: 11,
    SERIOUS: 12,
    JOLLY: 13,
    NAIVE: 14,
    MODEST: 15,
    MILD: 16,
    QUIET: 17,
    BASHFUL: 18,
    RASH: 19,
    CALM: 20,
    GENTLE: 21,
    SASSY: 22,
    CAREFUL: 23,
    QUIRKY: 24,

    0: "HARDY",
    1: "LONELY",
    2: "BRAVE",
    3: "ADAMANT",
    4: "NAUGHTY",
    5: "BOLD",
    6: "DOCILE",
    7: "RELAXED",
    8: "IMPISH",
    9: "LAX",
    10: "TIMID",
    11: "HASTY",
    12: "SERIOUS",
    13: "JOLLY",
    14: "NAIVE",
    15: "MODEST",
    16: "MILD",
    17: "QUIET",
    18: "BASHFUL",
    19: "RASH",
    20: "CALM",
    21: "GENTLE",
    22: "SASSY",
    23: "CAREFUL",
    24: "QUIRKY",
  });

  PokesavDsGen4.Game = Object.freeze({
    UNKNOWN: 0,
    HEART_GOLD_SOUL_SILVER: 8,
    DIAMOND_PEARL: 10,
    PLATINUM: 12,

    0: "UNKNOWN",
    8: "HEART_GOLD_SOUL_SILVER",
    10: "DIAMOND_PEARL",
    12: "PLATINUM",
  });

  function PokesavDsGen4(_io, _parent, _root) {
    this._io = _io;
    this._parent = _parent;
    this._root = _root || this;

    this._read();
  }
  PokesavDsGen4.prototype._read = function() {
  }

  var PokemonBlockD = PokesavDsGen4.PokemonBlockD = (function() {
    function PokemonBlockD(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonBlockD.prototype._read = function() {
      this._raw_originalTrainerName = this._io.readBytes((2 * 8));
      var _process = new StringDecode();
      this.originalTrainerName = _process.decode(this._raw_originalTrainerName);
      this.dateEggReceived = this._io.readBytes(3);
      this.dateMet = this._io.readBytes(3);
      this.diamondPearlEggLocation = this._io.readU2le();
      this.diamondPearlMetAtLocation = this._io.readU2le();
      this.pokerus = this._io.readU1();
      this.pokeball = this._io.readU1();
      this.originalTrainerGender = this._io.readBitsInt(1);
      this.metAtLevel = this._io.readBitsInt(7);
      this._io.alignToByte();
      this.encounterType = this._io.readU1();
      this.hgssPokeball = this._io.readU1();
      this.unused = this._io.readU1();
    }

    return PokemonBlockD;
  })();

  var StorageBlock = PokesavDsGen4.StorageBlock = (function() {
    function StorageBlock(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    StorageBlock.prototype._read = function() {
      this.tmpJunk = this._io.readBytes((this._parent.storageBlockSize - 20));
      this.footer = new GeneralAndStorageBlockFooter(this._io, this, this._root);
    }

    return StorageBlock;
  })();

  var PokemonBlockC = PokesavDsGen4.PokemonBlockC = (function() {
    function PokemonBlockC(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonBlockC.prototype._read = function() {
      this._raw_nickname = this._io.readBytes((2 * 11));
      var _process = new StringDecode();
      this.nickname = _process.decode(this._raw_nickname);
      this.unused = this._io.readU1();
      this.originGame = this._io.readU1();
      this.sinnohRibbonSet3 = this._io.readU2le();
      this.sinnohRibbonSet4 = this._io.readU2le();
      this.unused2 = this._io.readBytes(4);
    }

    return PokemonBlockC;
  })();

  var TestThing = PokesavDsGen4.TestThing = (function() {
    function TestThing(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    TestThing.prototype._read = function() {
      this.first = this._io.readBitsInt(10);
      this.second = this._io.readBitsInt(6);
    }

    return TestThing;
  })();

  var Playtime = PokesavDsGen4.Playtime = (function() {
    function Playtime(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    Playtime.prototype._read = function() {
      this.hours = this._io.readU2le();
      this.minutes = this._io.readU1();
      this.seconds = this._io.readU1();
    }

    return Playtime;
  })();

  var Evs = PokesavDsGen4.Evs = (function() {
    function Evs(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    Evs.prototype._read = function() {
      this.hp = this._io.readU1();
      this.attack = this._io.readU1();
      this.defense = this._io.readU1();
      this.speed = this._io.readU1();
      this.specialAttack = this._io.readU1();
      this.specialDefense = this._io.readU1();
    }

    return Evs;
  })();

  var GeneralAndStorageBlockFooter = PokesavDsGen4.GeneralAndStorageBlockFooter = (function() {
    function GeneralAndStorageBlockFooter(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    GeneralAndStorageBlockFooter.prototype._read = function() {
      this.storageBlockSaveCount = this._io.readU4le();
      this.generalBlockSaveCount = this._io.readU4le();
      this.blockSize = this._io.readU4le();
      this.runtimeJunk = this._io.readBytes(6);
      this.checksum = this._io.readU2le();
    }

    return GeneralAndStorageBlockFooter;
  })();

  var GeneralBlock = PokesavDsGen4.GeneralBlock = (function() {
    function GeneralBlock(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    GeneralBlock.prototype._read = function() {
      this.junk = this._io.readBytes((this._parent.generalBlockSize - 20));
      this.footer = new GeneralAndStorageBlockFooter(this._io, this, this._root);
    }
    Object.defineProperty(GeneralBlock.prototype, 'partyPokemonCount', {
      get: function() {
        if (this._m_partyPokemonCount !== undefined)
          return this._m_partyPokemonCount;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isPlatinum ? 156 : 148));
        this._m_partyPokemonCount = this._io.readU1();
        this._io.seek(_pos);
        return this._m_partyPokemonCount;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'trainerName', {
      get: function() {
        if (this._m_trainerName !== undefined)
          return this._m_trainerName;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isPlatinum ? 104 : 100));
        this._raw__m_trainerName = this._io.readBytes((2 * 8));
        var _process = new StringDecode();
        this._m_trainerName = _process.decode(this._raw__m_trainerName);
        this._io.seek(_pos);
        return this._m_trainerName;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'totalPlaytimeSeconds', {
      get: function() {
        if (this._m_totalPlaytimeSeconds !== undefined)
          return this._m_totalPlaytimeSeconds;
        this._m_totalPlaytimeSeconds = ((((this.playtime.hours * 60) * 60) + (this.playtime.minutes * 60)) + this.playtime.seconds);
        return this._m_totalPlaytimeSeconds;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'leagueChampTime', {
      get: function() {
        if (this._m_leagueChampTime !== undefined)
          return this._m_leagueChampTime;
        var _pos = this._io.pos;
        this._io.seek(60);
        this._m_leagueChampTime = this._io.readU4le();
        this._io.seek(_pos);
        return this._m_leagueChampTime;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'multiplayerAvatar', {
      get: function() {
        if (this._m_multiplayerAvatar !== undefined)
          return this._m_multiplayerAvatar;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isPlatinum ? 131 : 127));
        this._m_multiplayerAvatar = this._io.readU1();
        this._io.seek(_pos);
        return this._m_multiplayerAvatar;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'adventureStartTime', {
      get: function() {
        if (this._m_adventureStartTime !== undefined)
          return this._m_adventureStartTime;
        var _pos = this._io.pos;
        this._io.seek(52);
        this._m_adventureStartTime = this._io.readU4le();
        this._io.seek(_pos);
        return this._m_adventureStartTime;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'trainerGender', {
      get: function() {
        if (this._m_trainerGender !== undefined)
          return this._m_trainerGender;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isPlatinum ? 128 : 124));
        this._m_trainerGender = this._io.readU1();
        this._io.seek(_pos);
        return this._m_trainerGender;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'trainerId', {
      get: function() {
        if (this._m_trainerId !== undefined)
          return this._m_trainerId;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isPlatinum ? 120 : 116));
        this._m_trainerId = this._io.readU2le();
        this._io.seek(_pos);
        return this._m_trainerId;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'badgeFlags', {
      get: function() {
        if (this._m_badgeFlags !== undefined)
          return this._m_badgeFlags;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isPlatinum ? 130 : 126));
        this._m_badgeFlags = this._io.readU1();
        this._io.seek(_pos);
        return this._m_badgeFlags;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'trainerCardSignature', {
      get: function() {
        if (this._m_trainerCardSignature !== undefined)
          return this._m_trainerCardSignature;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isPlatinum ? 23464 : (this._parent.isHgss ? 17720 : 22788)));
        this._raw__m_trainerCardSignature = this._io.readBytes(1536);
        var _process = new TrainerCardSignatureReorder(Math.floor(192 / 8));
        this._m_trainerCardSignature = _process.decode(this._raw__m_trainerCardSignature);
        this._io.seek(_pos);
        return this._m_trainerCardSignature;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'playtime', {
      get: function() {
        if (this._m_playtime !== undefined)
          return this._m_playtime;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isPlatinum ? 138 : 134));
        this._m_playtime = new Playtime(this._io, this, this._root);
        this._io.seek(_pos);
        return this._m_playtime;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'money', {
      get: function() {
        if (this._m_money !== undefined)
          return this._m_money;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isPlatinum ? 124 : 120));
        this._m_money = this._io.readU4le();
        this._io.seek(_pos);
        return this._m_money;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'countryOfOrigin', {
      get: function() {
        if (this._m_countryOfOrigin !== undefined)
          return this._m_countryOfOrigin;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isPlatinum ? 129 : 125));
        this._m_countryOfOrigin = this._io.readU1();
        this._io.seek(_pos);
        return this._m_countryOfOrigin;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'secretId', {
      get: function() {
        if (this._m_secretId !== undefined)
          return this._m_secretId;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isPlatinum ? 122 : 118));
        this._m_secretId = this._io.readU2le();
        this._io.seek(_pos);
        return this._m_secretId;
      }
    });
    Object.defineProperty(GeneralBlock.prototype, 'partyPokemon', {
      get: function() {
        if (this._m_partyPokemon !== undefined)
          return this._m_partyPokemon;
        var _pos = this._io.pos;
        this._io.seek((this._parent.isPlatinum ? 160 : 152));
        this._m_partyPokemon = new Array(this.partyPokemonCount);
        for (var i = 0; i < this.partyPokemonCount; i++) {
          this._m_partyPokemon[i] = new PokemonInParty(this._io, this, this._root);
        }
        this._io.seek(_pos);
        return this._m_partyPokemon;
      }
    });

    return GeneralBlock;
  })();

  var Ivs = PokesavDsGen4.Ivs = (function() {
    function Ivs(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    Ivs.prototype._read = function() {
      this.flags = this._io.readU4le();
    }
    Object.defineProperty(Ivs.prototype, 'hp', {
      get: function() {
        if (this._m_hp !== undefined)
          return this._m_hp;
        this._m_hp = ((this.flags >>> 0) & 31);
        return this._m_hp;
      }
    });
    Object.defineProperty(Ivs.prototype, 'defense', {
      get: function() {
        if (this._m_defense !== undefined)
          return this._m_defense;
        this._m_defense = ((this.flags >>> 10) & 31);
        return this._m_defense;
      }
    });
    Object.defineProperty(Ivs.prototype, 'isNicknamed', {
      get: function() {
        if (this._m_isNicknamed !== undefined)
          return this._m_isNicknamed;
        this._m_isNicknamed = ((this.flags >>> 31) & 1) == 1;
        return this._m_isNicknamed;
      }
    });
    Object.defineProperty(Ivs.prototype, 'specialAttack', {
      get: function() {
        if (this._m_specialAttack !== undefined)
          return this._m_specialAttack;
        this._m_specialAttack = ((this.flags >>> 20) & 31);
        return this._m_specialAttack;
      }
    });
    Object.defineProperty(Ivs.prototype, 'isEgg', {
      get: function() {
        if (this._m_isEgg !== undefined)
          return this._m_isEgg;
        this._m_isEgg = ((this.flags >>> 30) & 1) == 1;
        return this._m_isEgg;
      }
    });
    Object.defineProperty(Ivs.prototype, 'attack', {
      get: function() {
        if (this._m_attack !== undefined)
          return this._m_attack;
        this._m_attack = ((this.flags >>> 5) & 31);
        return this._m_attack;
      }
    });
    Object.defineProperty(Ivs.prototype, 'specialDefense', {
      get: function() {
        if (this._m_specialDefense !== undefined)
          return this._m_specialDefense;
        this._m_specialDefense = ((this.flags >>> 25) & 31);
        return this._m_specialDefense;
      }
    });
    Object.defineProperty(Ivs.prototype, 'speed', {
      get: function() {
        if (this._m_speed !== undefined)
          return this._m_speed;
        this._m_speed = ((this.flags >>> 15) & 31);
        return this._m_speed;
      }
    });

    return Ivs;
  })();

  var PokemonBlockB = PokesavDsGen4.PokemonBlockB = (function() {
    function PokemonBlockB(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonBlockB.prototype._read = function() {
      this.moves = new Array(4);
      for (var i = 0; i < 4; i++) {
        this.moves[i] = this._io.readU2le();
      }
      this.movePps = new Array(4);
      for (var i = 0; i < 4; i++) {
        this.movePps[i] = this._io.readU1();
      }
      this.movePpUps = new Array(4);
      for (var i = 0; i < 4; i++) {
        this.movePpUps[i] = this._io.readU1();
      }
      this.iv = new Ivs(this._io, this, this._root);
      this.hoennRibbonSet1 = this._io.readU2le();
      this.hoennRibbonSet2 = this._io.readU2le();
      this.forme = this._io.readBitsInt(5);
      this.isGenderless = this._io.readBitsInt(1) != 0;
      this.isFemale = this._io.readBitsInt(1) != 0;
      this.fatefulEncounter = this._io.readBitsInt(1) != 0;
      this._io.alignToByte();
      this.hgssShinyLeaves = this._io.readU1();
      this.unused = this._io.readBytes(2);
      this.platinumEggLocation = this._io.readU2le();
      this.platinumMetAtLocation = this._io.readU2le();
    }
    Object.defineProperty(PokemonBlockB.prototype, 'isEgg', {
      get: function() {
        if (this._m_isEgg !== undefined)
          return this._m_isEgg;
        this._m_isEgg = this.iv.isEgg;
        return this._m_isEgg;
      }
    });
    Object.defineProperty(PokemonBlockB.prototype, 'isNicknamed', {
      get: function() {
        if (this._m_isNicknamed !== undefined)
          return this._m_isNicknamed;
        this._m_isNicknamed = this.iv.isNicknamed;
        return this._m_isNicknamed;
      }
    });

    return PokemonBlockB;
  })();

  var PokemonBattleStats = PokesavDsGen4.PokemonBattleStats = (function() {
    function PokemonBattleStats(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonBattleStats.prototype._read = function() {
      this.asleepTurnCount = this._io.readBitsInt(3);
      this.isPoisoned = this._io.readBitsInt(1) != 0;
      this.isBurned = this._io.readBitsInt(1) != 0;
      this.isFrozen = this._io.readBitsInt(1) != 0;
      this.isParalyzed = this._io.readBitsInt(1) != 0;
      this.isToxic = this._io.readBitsInt(1) != 0;
      this._io.alignToByte();
      this.unknown = this._io.readBytes(3);
      this.level = this._io.readU1();
      this.capsuleIndex = this._io.readU1();
      this.currentHp = this._io.readU2le();
      this.stats = new PokemonStats(this._io, this, this._root);
      this.unknown2 = this._io.readBytes(56);
      this.sealCoordinates = this._io.readBytes(24);
    }

    return PokemonBattleStats;
  })();

  var HallOfHameFooter = PokesavDsGen4.HallOfHameFooter = (function() {
    function HallOfHameFooter(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    HallOfHameFooter.prototype._read = function() {
      this.saveId = this._io.readU4le();
      this.saveIndex = this._io.readU4le();
      this.runtimeJunk = this._io.readBytes(8);
      this.blockSize = this._io.readU4le();
      this.runtimeJunk2 = this._io.readBytes(2);
      this.checksum = this._io.readU2le();
    }

    return HallOfHameFooter;
  })();

  var PokemonData = PokesavDsGen4.PokemonData = (function() {
    function PokemonData(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonData.prototype._read = function() {
      this._raw_blockA = this._io.readBytes(32);
      var _io__raw_blockA = new KaitaiStream(this._raw_blockA);
      this.blockA = new PokemonBlockA(_io__raw_blockA, this, this._root);
      this._raw_blockB = this._io.readBytes(32);
      var _io__raw_blockB = new KaitaiStream(this._raw_blockB);
      this.blockB = new PokemonBlockB(_io__raw_blockB, this, this._root);
      this._raw_blockC = this._io.readBytes(32);
      var _io__raw_blockC = new KaitaiStream(this._raw_blockC);
      this.blockC = new PokemonBlockC(_io__raw_blockC, this, this._root);
      this._raw_blockD = this._io.readBytes(32);
      var _io__raw_blockD = new KaitaiStream(this._raw_blockD);
      this.blockD = new PokemonBlockD(_io__raw_blockD, this, this._root);
    }

    return PokemonData;
  })();

  var PokemonInParty = PokesavDsGen4.PokemonInParty = (function() {
    function PokemonInParty(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonInParty.prototype._read = function() {
      this.base = new Pokemon(this._io, this, this._root);
      this._raw__raw_battleStats = this._io.readBytes(100);
      var _process = new PokemonDecrypt(this.base.personalityValue);
      this._raw_battleStats = _process.decode(this._raw__raw_battleStats);
      var _io__raw_battleStats = new KaitaiStream(this._raw_battleStats);
      this.battleStats = new PokemonBattleStats(_io__raw_battleStats, this, this._root);
    }

    return PokemonInParty;
  })();

  var Pokemon = PokesavDsGen4.Pokemon = (function() {
    function Pokemon(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    Pokemon.prototype._read = function() {
      this.personalityValue = this._io.readU4le();
      this.junk = this._io.readBytes(2);
      this.checksum = this._io.readU2le();
      this._raw__raw_data = this._io.readBytes(128);
      var _process = new PokemonDecryptShuffle(this.blockOrder, this.checksum);
      this._raw_data = _process.decode(this._raw__raw_data);
      var _io__raw_data = new KaitaiStream(this._raw_data);
      this.data = new PokemonData(_io__raw_data, this, this._root);
    }
    Object.defineProperty(Pokemon.prototype, 'isShiny', {
      get: function() {
        if (this._m_isShiny !== undefined)
          return this._m_isShiny;
        this._m_isShiny = (((this.blockA.originalTrainerId ^ this.blockA.originalTrainerSecretId) ^ (this.personalityValue & 65535)) ^ ((this.personalityValue >>> 16) & 65535)) < 8;
        return this._m_isShiny;
      }
    });
    Object.defineProperty(Pokemon.prototype, 'blockOrder', {
      get: function() {
        if (this._m_blockOrder !== undefined)
          return this._m_blockOrder;
        this._m_blockOrder = KaitaiStream.mod(((this.personalityValue >>> 13) & 31), 24);
        return this._m_blockOrder;
      }
    });
    Object.defineProperty(Pokemon.prototype, 'blockC', {
      get: function() {
        if (this._m_blockC !== undefined)
          return this._m_blockC;
        this._m_blockC = this.data.blockC;
        return this._m_blockC;
      }
    });
    Object.defineProperty(Pokemon.prototype, 'blockD', {
      get: function() {
        if (this._m_blockD !== undefined)
          return this._m_blockD;
        this._m_blockD = this.data.blockD;
        return this._m_blockD;
      }
    });
    Object.defineProperty(Pokemon.prototype, 'blockB', {
      get: function() {
        if (this._m_blockB !== undefined)
          return this._m_blockB;
        this._m_blockB = this.data.blockB;
        return this._m_blockB;
      }
    });
    Object.defineProperty(Pokemon.prototype, 'blockA', {
      get: function() {
        if (this._m_blockA !== undefined)
          return this._m_blockA;
        this._m_blockA = this.data.blockA;
        return this._m_blockA;
      }
    });
    Object.defineProperty(Pokemon.prototype, 'nature', {
      get: function() {
        if (this._m_nature !== undefined)
          return this._m_nature;
        this._m_nature = KaitaiStream.mod(this.personalityValue, 25);
        return this._m_nature;
      }
    });

    return Pokemon;
  })();

  var PokemonBlockA = PokesavDsGen4.PokemonBlockA = (function() {
    function PokemonBlockA(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonBlockA.prototype._read = function() {
      this.nationalPokedexId = this._io.readU2le();
      this.heldItem = this._io.readU2le();
      this.originalTrainerId = this._io.readU2le();
      this.originalTrainerSecretId = this._io.readU2le();
      this.experiencePoints = this._io.readU4le();
      this.friendship = this._io.readU1();
      this.ability = this._io.readU1();
      this.markings = this._io.readU1();
      this.originalLanguage = this._io.readU1();
      this.ev = new Evs(this._io, this, this._root);
      this.contestValues = this._io.readBytes(6);
      this.sinnohRibbonSet1 = this._io.readU2le();
      this.sinnohRibbonSet2 = this._io.readU2le();
    }

    return PokemonBlockA;
  })();

  var PokemonStats = PokesavDsGen4.PokemonStats = (function() {
    function PokemonStats(_io, _parent, _root) {
      this._io = _io;
      this._parent = _parent;
      this._root = _root || this;

      this._read();
    }
    PokemonStats.prototype._read = function() {
      this.hp = this._io.readU2le();
      this.attack = this._io.readU2le();
      this.defense = this._io.readU2le();
      this.speed = this._io.readU2le();
      this.specialAttack = this._io.readU2le();
      this.specialDefense = this._io.readU2le();
    }

    return PokemonStats;
  })();
  Object.defineProperty(PokesavDsGen4.prototype, 'storageBlock1', {
    get: function() {
      if (this._m_storageBlock1 !== undefined)
        return this._m_storageBlock1;
      var _pos = this._io.pos;
      this._io.seek(this.storageBlockOffset);
      this._raw__m_storageBlock1 = this._io.readBytes(this.storageBlockSize);
      var _io__raw__m_storageBlock1 = new KaitaiStream(this._raw__m_storageBlock1);
      this._m_storageBlock1 = new StorageBlock(_io__raw__m_storageBlock1, this, this._root);
      this._io.seek(_pos);
      return this._m_storageBlock1;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'diamondPearlFirstStorageBlockSize', {
    get: function() {
      if (this._m_diamondPearlFirstStorageBlockSize !== undefined)
        return this._m_diamondPearlFirstStorageBlockSize;
      var _pos = this._io.pos;
      this._io.seek((((311552 + 74208) - 20) + 8));
      this._m_diamondPearlFirstStorageBlockSize = this._io.readU4le();
      this._io.seek(_pos);
      return this._m_diamondPearlFirstStorageBlockSize;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'platinumFirstStorageBlockSize', {
    get: function() {
      if (this._m_platinumFirstStorageBlockSize !== undefined)
        return this._m_platinumFirstStorageBlockSize;
      var _pos = this._io.pos;
      this._io.seek((((315180 + 74212) - 20) + 8));
      this._m_platinumFirstStorageBlockSize = this._io.readU4le();
      this._io.seek(_pos);
      return this._m_platinumFirstStorageBlockSize;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'hasBackup', {
    get: function() {
      if (this._m_hasBackup !== undefined)
        return this._m_hasBackup;
      this._m_hasBackup = this.generalBlock1.footer.generalBlockSaveCount != 4294967295;
      return this._m_hasBackup;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'generalBlockCurrent', {
    get: function() {
      if (this._m_generalBlockCurrent !== undefined)
        return this._m_generalBlockCurrent;
      this._m_generalBlockCurrent = ( ((this.hasBackup) && (this.generalBlock1.footer.generalBlockSaveCount > this.generalBlock2.footer.generalBlockSaveCount))  ? this.generalBlock1 : this.generalBlock2);
      return this._m_generalBlockCurrent;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'hgssFirstStorageBlockSize', {
    get: function() {
      if (this._m_hgssFirstStorageBlockSize !== undefined)
        return this._m_hgssFirstStorageBlockSize;
      var _pos = this._io.pos;
      this._io.seek((((325376 + 74512) - 20) + 8));
      this._m_hgssFirstStorageBlockSize = this._io.readU4le();
      this._io.seek(_pos);
      return this._m_hgssFirstStorageBlockSize;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'generalBlock1', {
    get: function() {
      if (this._m_generalBlock1 !== undefined)
        return this._m_generalBlock1;
      var _pos = this._io.pos;
      this._io.seek(this.generalBlockOffset);
      this._raw__m_generalBlock1 = this._io.readBytes(this.generalBlockSize);
      var _io__raw__m_generalBlock1 = new KaitaiStream(this._raw__m_generalBlock1);
      this._m_generalBlock1 = new GeneralBlock(_io__raw__m_generalBlock1, this, this._root);
      this._io.seek(_pos);
      return this._m_generalBlock1;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'storageBlockBackup', {
    get: function() {
      if (this._m_storageBlockBackup !== undefined)
        return this._m_storageBlockBackup;
      this._m_storageBlockBackup = ( ((this.hasBackup) && (this.storageBlock1.footer.storageBlockSaveCount == this.generalBlockCurrent.footer.storageBlockSaveCount))  ? this.storageBlock2 : this.storageBlock1);
      return this._m_storageBlockBackup;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'hgssFirstGeneralBlockSize', {
    get: function() {
      if (this._m_hgssFirstGeneralBlockSize !== undefined)
        return this._m_hgssFirstGeneralBlockSize;
      var _pos = this._io.pos;
      this._io.seek((((262144 + 63016) - 20) + 8));
      this._m_hgssFirstGeneralBlockSize = this._io.readU4le();
      this._io.seek(_pos);
      return this._m_hgssFirstGeneralBlockSize;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'storageBlockSize', {
    get: function() {
      if (this._m_storageBlockSize !== undefined)
        return this._m_storageBlockSize;
      this._m_storageBlockSize = (this.isPlatinum ? 74212 : (this.isHgss ? 74512 : 74208));
      return this._m_storageBlockSize;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'isDiamondPearl', {
    get: function() {
      if (this._m_isDiamondPearl !== undefined)
        return this._m_isDiamondPearl;
      this._m_isDiamondPearl =  ((this.diamondPearlFirstGeneralBlockSize == 49408) && (this.diamondPearlFirstStorageBlockSize == 74208)) ;
      return this._m_isDiamondPearl;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'storageBlock2', {
    get: function() {
      if (this._m_storageBlock2 !== undefined)
        return this._m_storageBlock2;
      var _pos = this._io.pos;
      this._io.seek((262144 + this.storageBlockOffset));
      this._raw__m_storageBlock2 = this._io.readBytes(this.storageBlockSize);
      var _io__raw__m_storageBlock2 = new KaitaiStream(this._raw__m_storageBlock2);
      this._m_storageBlock2 = new StorageBlock(_io__raw__m_storageBlock2, this, this._root);
      this._io.seek(_pos);
      return this._m_storageBlock2;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'generalBlockSize', {
    get: function() {
      if (this._m_generalBlockSize !== undefined)
        return this._m_generalBlockSize;
      this._m_generalBlockSize = (this.isPlatinum ? 53036 : (this.isHgss ? 63016 : 49408));
      return this._m_generalBlockSize;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'isHgss', {
    get: function() {
      if (this._m_isHgss !== undefined)
        return this._m_isHgss;
      this._m_isHgss =  ((this.hgssFirstGeneralBlockSize == 63016) && (this.hgssFirstStorageBlockSize == 74512)) ;
      return this._m_isHgss;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'storageBlockOffset', {
    get: function() {
      if (this._m_storageBlockOffset !== undefined)
        return this._m_storageBlockOffset;
      this._m_storageBlockOffset = (this.isPlatinum ? 53036 : (this.isHgss ? 63232 : 49408));
      return this._m_storageBlockOffset;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'diamondPearlFirstGeneralBlockSize', {
    get: function() {
      if (this._m_diamondPearlFirstGeneralBlockSize !== undefined)
        return this._m_diamondPearlFirstGeneralBlockSize;
      var _pos = this._io.pos;
      this._io.seek((((262144 + 49408) - 20) + 8));
      this._m_diamondPearlFirstGeneralBlockSize = this._io.readU4le();
      this._io.seek(_pos);
      return this._m_diamondPearlFirstGeneralBlockSize;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'generalBlock2', {
    get: function() {
      if (this._m_generalBlock2 !== undefined)
        return this._m_generalBlock2;
      var _pos = this._io.pos;
      this._io.seek((262144 + this.generalBlockOffset));
      this._raw__m_generalBlock2 = this._io.readBytes(this.generalBlockSize);
      var _io__raw__m_generalBlock2 = new KaitaiStream(this._raw__m_generalBlock2);
      this._m_generalBlock2 = new GeneralBlock(_io__raw__m_generalBlock2, this, this._root);
      this._io.seek(_pos);
      return this._m_generalBlock2;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'generalBlockOffset', {
    get: function() {
      if (this._m_generalBlockOffset !== undefined)
        return this._m_generalBlockOffset;
      this._m_generalBlockOffset = 0;
      return this._m_generalBlockOffset;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'generalBlockBackup', {
    get: function() {
      if (this._m_generalBlockBackup !== undefined)
        return this._m_generalBlockBackup;
      this._m_generalBlockBackup = ( ((this.hasBackup) && (this.generalBlock1.footer.generalBlockSaveCount > this.generalBlock2.footer.generalBlockSaveCount))  ? this.generalBlock2 : this.generalBlock1);
      return this._m_generalBlockBackup;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'platinumFirstGeneralBlockSize', {
    get: function() {
      if (this._m_platinumFirstGeneralBlockSize !== undefined)
        return this._m_platinumFirstGeneralBlockSize;
      var _pos = this._io.pos;
      this._io.seek((((262144 + 53036) - 20) + 8));
      this._m_platinumFirstGeneralBlockSize = this._io.readU4le();
      this._io.seek(_pos);
      return this._m_platinumFirstGeneralBlockSize;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'storageBlockCurrent', {
    get: function() {
      if (this._m_storageBlockCurrent !== undefined)
        return this._m_storageBlockCurrent;
      this._m_storageBlockCurrent = ( ((this.hasBackup) && (this.storageBlock1.footer.storageBlockSaveCount == this.generalBlockCurrent.footer.storageBlockSaveCount))  ? this.storageBlock1 : this.storageBlock2);
      return this._m_storageBlockCurrent;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'game', {
    get: function() {
      if (this._m_game !== undefined)
        return this._m_game;
      this._m_game = (this.isDiamondPearl ? PokesavDsGen4.Game.DIAMOND_PEARL : (this.isPlatinum ? PokesavDsGen4.Game.PLATINUM : (this.isHgss ? PokesavDsGen4.Game.HEART_GOLD_SOUL_SILVER : PokesavDsGen4.Game.UNKNOWN)));
      return this._m_game;
    }
  });
  Object.defineProperty(PokesavDsGen4.prototype, 'isPlatinum', {
    get: function() {
      if (this._m_isPlatinum !== undefined)
        return this._m_isPlatinum;
      this._m_isPlatinum =  ((this.platinumFirstGeneralBlockSize == 53036) && (this.platinumFirstStorageBlockSize == 74212)) ;
      return this._m_isPlatinum;
    }
  });

  return PokesavDsGen4;
})();
return PokesavDsGen4;
}));
