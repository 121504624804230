(function() {
  var xorAt,
    __slice = [].slice;

  xorAt = function(encryptionKey, buffer, offset) {
    return buffer.writeInt32LE(encryptionKey ^ (buffer.readInt32LE(offset)), offset);
  };

  module.exports = {
    pokemonData: {
      decrypt: function(encryptionKey, buffer) {
        var i, _i, _ref;
        for (i = _i = 0, _ref = buffer.length; _i < _ref; i = _i += 4) {
          xorAt(encryptionKey, buffer, i);
        }
        return buffer;
      },
      encrypt: function() {
        var a, _ref;
        a = 1 <= arguments.length ? __slice.call(arguments, 0) : [];
        return (_ref = module.exports.pokemonData).decrypt.apply(_ref, a);
      }
    }
  };

}).call(this);
