(function() {
  var typeExpResolver, _;

  _ = require('lodash');

  typeExpResolver = function(type, exp) {
    return "" + type + "-" + exp;
  };

  exports.levelFromExperience = _.memoize(function(type, exp) {
    var level;
    if (!(type === 'slow' || type === 'medium-slow' || type === 'medium-fast' || type === 'fast' || type === 'erratic' || type === 'fluctuating')) {
      return -1;
    }
    level = 0;
    while (level < 100 && (exports.calculate(type, level + 1)) <= exp) {
      level++;
    }
    return level;
  }, typeExpResolver);

  exports.calculate = function(type, minLvl) {
    var _base, _ref;
    return (_ref = typeof (_base = exports.growthRateFunctions)[type] === "function" ? _base[type](minLvl) : void 0) != null ? _ref : 0;
  };

  exports.growthRateFunctions = {
    'slow': function(n) {
      if (n <= 1) {
        return 0;
      } else {
        return Math.floor(5 * Math.pow(n, 3) / 4);
      }
    },
    'medium-slow': function(n) {
      if (n <= 1) {
        return 0;
      } else {
        return Math.floor((6 / 5) * Math.pow(n, 3) - 15 * n * n + 100 * n - 140);
      }
    },
    'medium-fast': function(n) {
      if (n <= 1) {
        return 0;
      } else {
        return Math.pow(n, 3);
      }
    },
    'fast': function(n) {
      return Math.floor(4 * Math.pow(n, 3) / 5);
    },
    'erratic': function(n) {
      switch (false) {
        case !(n <= 1):
          return 0;
        case !(n <= 50):
          return Math.floor((Math.pow(n, 3) * (100 - n)) / 50);
        case !((50 <= n && n <= 68)):
          return Math.floor((Math.pow(n, 3) * (150 - n)) / 100);
        case !((68 <= n && n <= 98)):
          return Math.floor((Math.pow(n, 3) * (Math.floor((1911 - 10 * n) / 3))) / 500);
        case !(98 <= n):
          return Math.floor((Math.pow(n, 3) * (160 - n)) / 100);
      }
    },
    'fluctuating': function(n) {
      return Math.floor((function() {
        switch (false) {
          case !(n <= 15):
            return Math.pow(n, 3) * (Math.floor((n + 1) / 3) + 24) / 50;
          case !((15 <= n && n <= 36)):
            return Math.pow(n, 3) * ((n + 14) / 50);
          case !(36 <= n):
            return Math.pow(n, 3) * ((Math.floor(n / 2) + 32) / 50);
        }
      })());
    }
  };

}).call(this);
